export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'TwilioVideoApp-selectedBackgroundSettings';

export const GALLERY_VIEW_ASPECT_RATIO = 9 / 16; // 16:9
export const GALLERY_VIEW_MARGIN = 3;

export const WHATSAPP_PREFIX = "whatsapp:+";
export const SMS_PREFIX = "+";
export const MAX_FILE_SIZE = 52428800;
export const COPY_SUCCESS_MESSAGE = "Message copied.";
export const CONNECTION_ERROR_MESSAGE = "No internet connection.";
export const NOTIFICATION_TIMEOUT = 4000;
export const ERROR_MODAL_MESSAGES = {
  ADD_PARTICIPANT: {
    title: "Unable to add participant",
    description: "There is an error while adding participant to conversation",
  },
  CHANGE_CONVERSATION_NAME: {
    title: "Unable to save Conversation name",
    description:
      "Only creators of the Conversation can edit the Conversation name.",
  },
};
export const CONVERSATION_MESSAGES = {
  CREATED: "Conversation created.",
  NAME_CHANGED: "Conversation name changed.",
  LEFT: "You left the conversation.",
};

export const PARTICIPANT_MESSAGES = {
  ADDED: "Participant added.",
  REMOVED: "Participant removed.",
};

export const USER_PROFILE_MESSAGES = {
  FRIENDLY_NAME_UPDATED: "User profile friendly name updated.",
};

export const CONVERSATION_PAGE_SIZE = 30;

export const NOTIFICATION_LEVEL = {
  DEFAULT: "default",
  MUTED: "muted",
};

export const MAX_MESSAGE_LINE_WIDTH = 75;

export const NUMBEREREGEX = /.*[0-9].*/;
export const SPECIALCHAREREGEX = /.*[!@#$%^&*].*/;
export const LETTERS_ONLY_REGEX = /^[A-Za-z]*$/;