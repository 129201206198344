import React, { useState, useCallback, memo, useEffect, useRef } from 'react';
import {
  useParticipantIds,
  useScreenShare,
  useDailyEvent,
  useLocalSessionId,
  useDaily,
} from '@daily-co/daily-react';
import UserMediaError from '../UserMediaError';
import { Box } from '@mui/material';
import Chat from '../Chat/Chat';
import { drawerWidth } from '../Constants';
import Pagination from '../Pagination/Pagination';
import { DailyUtilsProvider } from '../useDailyUtils';
import GalleryLayout from './GalleryLayout';
import Participants from '../Participants/Participants';
import { useDailyAppRoom } from '../useDailyGlobalRoom';
import Mixer from '../../Mixer/Mixer';
import ChatMessageAlert from '../Chat/ChatMessageAlert';


function DailyCallGallery() {
  const callObject = useDaily();
  const [getUserMediaError, setGetUserMediaError] = useState(false);
  const { isStreamingView, isChatOpen, setIsChatOpen, isParticipantsOpen, setParticipantsOpen, isMixerOpen } = useDailyAppRoom();
  const isDrawerOpen = isChatOpen || isParticipantsOpen || isMixerOpen;

  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, []),
  );

  const { screens } = useScreenShare();
  // const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  // set username with a delay of 4 seconds to avoid flickering experience
  // Remove this below block including useEffect and use the hook at the top level
  const [remoteParticipantIds, setRemoteParticipantIds] = useState<any[]>([]); // Change to state
  const participantIds  = useParticipantIds({ filter: 'remote' }); // Call the hook at the top level
  const timerRef = useRef<NodeJS.Timeout | undefined>(undefined); // Change null to undefined

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Check if participants are being added or removed
    if (participantIds.length > remoteParticipantIds.length) {
      // If adding participants, update immediately
      setRemoteParticipantIds(participantIds);
    } else {
      // If removing participants, set a delay
      timerRef.current = setTimeout(() => {
        setRemoteParticipantIds(participantIds);
      }, 4000); 
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current); // Cleanup on unmount
      }
    };
  }, [participantIds]);


  // console.log("remoteParticipantIds", remoteParticipantIds)
  // debugger
  const participantsList = callObject?.participants();
  const remoteParticipantUserIds = remoteParticipantIds.filter(id => {
    const participant = participantsList?.[id];
    return participant?.user_name?.trim() !== ''; 
  });

  const localSessionId = useLocalSessionId();
  const isAlone = remoteParticipantUserIds.length < 1 || screens.length < 1;
  const allParticipants = [...(isStreamingView ? [] : [localSessionId]), ...remoteParticipantUserIds];
  const [videos, setVideos] = useState([1]); // videos will be removed inclusing state. keeping this for showing grid demo quickly
  const allParticipantsCount = allParticipants?.length || videos?.length; // videos will be removed inclusing state. keeping this for showing grid demo quickly
  // console.log('allParticipants', { allParticipants, localSessionId, remoteParticipantIds, screens })

  if (getUserMediaError) {
    return <UserMediaError />;
  }

  function DummyUtils() {
    const { isStreamingView, setIsStreamingView, setStreamingViewType } = useDailyAppRoom();
    return (
      <div style={{ position: 'fixed', top: '80px', zIndex: 99, display: 'initial' }}>
        <button onClick={() => {
          const updatedVids = [...videos, videos.length + 1];
          setVideos(updatedVids)
        }}>Add Video</button>
        <button disabled={videos?.length === 1} onClick={() => {
          const updatedVids = videos.slice(0, -1);
          setVideos(updatedVids);
        }}>Remove Video</button>
        <button onClick={() => setStreamingViewType('initial')}>Jam Session View</button>
        <button onClick={() => setStreamingViewType('soloFocus')}>LiveStresm | Solo Focus View</button>
        <button onClick={() => setStreamingViewType('soloRotate')}>LiveStresm | Solo Focus Rotate View</button>
        <button onClick={() => setStreamingViewType('ensemble')}>LiveStresm | Ensemble View</button>
        <button onClick={() => setIsStreamingView(!isStreamingView)}>Change view to Streaming</button>
      </div>
    )
  };

  return (
    // videos will be removed including state. keeping this for showing grid demo quickly
    <DailyUtilsProvider totalData={allParticipants || videos}>
      <Box sx={{ display: 'flex' }}>
        {/* Main Content */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            padding: 1,
            marginRight: isDrawerOpen ? `${drawerWidth}px` : 0,
            transition: 'margin 0.3s ease-in-out',
            width: isDrawerOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
            marginTop: '-20px',
          }}
        >
          <Box>
            <GalleryLayout
              screens={screens}
              isDrawerOpen={isDrawerOpen}
              localSessionId={localSessionId}
              isAlone={isAlone}
            />
            <Pagination participantsCount={allParticipantsCount} />
            {/* <DummyUtils/> */}
          </Box>
        </Box>
        {isChatOpen ?
          <Box sx={{ overflow: 'auto', padding: 2 }}>
            <Chat toggleChat={setIsChatOpen} />
          </Box>
          : !isStreamingView && <ChatMessageAlert />
        }

        {isParticipantsOpen &&
          <Box sx={{ overflow: 'auto', padding: 2 }}>
            <Participants toggleParticipants={setParticipantsOpen}
              remoteParticipantIds={remoteParticipantUserIds}
            />
          </Box>
        }
    
        {!isStreamingView && <Mixer mixer={isMixerOpen} />}
        
      </Box>
    </DailyUtilsProvider>
  );
}

export default memo(DailyCallGallery);