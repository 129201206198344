import { memo, useEffect, useRef, useState } from "react";
import { Grid, Slider, Typography, Box, Avatar } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
// import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import { useUser } from "../../context/UserContext";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Guitar from "../../images/Vector.svg";
import Drums from "../../images/drums.svg";
import Mic from "../../images/microphone7.svg";
import EditIcon from "../../images/editWhiteWOrangeBg.svg";
import MixerSliderEdit from "./MixerSliderEdit";
import Tooltip from "@mui/material/Tooltip";
import "./mixer.css";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import Tile from "../Daily/Tile/Tile";
import { useVideoTrack } from "@daily-co/daily-react";
import { stringToColor } from "../../utils";
import useInstrumentsAndIcons from "../../hooks/useGenresAndInstruments/useInstrumentsAndIcons";
import UpdateIcon from "../../icons/receive-square.svg";
import DeleteIcon from "../../icons/close-square.svg";
import NewIcon from "../../icons/add.svg";
import PresetDialogBox from "./PresetDialogBox";
import { ReactComponent as Unmute } from "../../images/microphone-2.svg";
import { ReactComponent as Mute } from "../../images/microphone-slash.svg";
import { ReactComponent as UnmuteBlue } from "../../images/unmute-blue.svg";
import { ReactComponent as MuteBlue } from "../../images/mute-blue.svg";
import { MixerSliderMuteIconWrapper } from "../Daily/DailyStyled";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		outerContainer: {
			display: "flex",
			alignItems: "center",
			position: "relative",
			flexDirection: "column",
			margin: "5px 0",
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: 1,
			[theme.breakpoints.down("sm")]: {
				"& svg": {
					transform: "scale(0.7)",
				},
			},
		},
		avatarContainer: {},
		sliderContainer: {
			display: "flex",
			justifyContent: "space-around",
			flexDirection: "column",
			bottom: 0,
			zIndex: 2,
			height: "100%",
			width: "100%",
		},
		volumeSlider: {
			display: "flex",
			height: "70%",
			flexDirection: "column",
			alignItems: "center",
			borderRadius: "6px",
			padding: "10px 0 0 0",
			"& .MuiSlider-thumb": {
				height: "30px",
				width: "30px",
				background: "#ff702a",
				border: "2px solid #8EBAFB",
				boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
			},
			"& .MuiSlider-mark": {
				width: "15px",
				backgroundColor: "#000000",
			},
			"& .MuiSlider-markLabel": {
				color: "#888888",
			},
		},
	})
);

export const getMixerSliderMuteIcon = (
	participantAudio: any,
	isInsideAvatar: boolean = false
) => {
	return (
		<IconButton>
			{participantAudio ?
				<>{isInsideAvatar ? <UnmuteBlue /> : <Unmute />}</> :
				<>{isInsideAvatar ? <MuteBlue /> : <Mute />}</>
			}
		</IconButton>
	)
}
export const getUserAvatar = (user: { firstName: string, lastName: string, profilePhotoUrl?: string | undefined, profilePhoto?: string | undefined }, isSmallSize = false) => {

	const userName = `${user?.firstName} ${user?.lastName}`;
	return (
		<Avatar
			alt={userName}
			src={`${process.env.REACT_APP_PICTURE_URL}${user?.profilePhotoUrl}`}
			sx={{
				width: isSmallSize ? 32 : 90,
				height: isSmallSize ? 32 : 90,
				bgcolor:
					user?.profilePhoto === null ? stringToColor(userName) : "",
				marginRight: "5px",
				textTransform: 'uppercase',
				fontSize: isSmallSize ? '16px' : '36px'
			}}
		>
			{user?.firstName[0]}{user?.lastName[0]}
		</Avatar>
	)
};

const MixerSlider = (props: any) => {
	const user = useUser();
	const classes = useStyles();
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	const userSession = localStorage.getItem("userSession") ?? "";
	const sessionID = localStorage.getItem("sessionId");

	const [presets, setPresets] = useState([
		"Preset 1",
		"Preset 2",
		"Preset 3",
		"Preset 4",
		"Preset 5",
		"Preset 6",  // This one should be ignored due to the 5-preset limit
	]);
	const [selectedPreset, setSelectedPreset] = useState("Default Preset");
	const [openPresetDialogBox, setOpenPresetDialogBox] = useState(false);
	const [type, setType] = useState<string | undefined>(undefined);

	const [userSessions, setUserSessions] = useState(
		JSON.parse(localStorage.getItem("userSession") ?? "")
	);

	const audioDetails: any = props?.participantAudioDetails || {};
	
	const [participantAudio, setParticipantAudio] = useState<boolean>(audioDetails?.audio?.master?.audio_status !== "mute");
	const [userCheckedMap, setUserCheckedMap] = useState(() => {
		return JSON.parse(localStorage.getItem("userCheckedMap") ?? "{}");
	});

	const [checked, setChecked] = useState(() => {
		const userId = props?.participant?.email; // Assuming this is a unique identifier for the user
		return userCheckedMap[userId] ?? false; // Default to false if not found
	});

	const [channel1Volume, setChannel1Volume] = useState<any>(audioDetails?.audio?.channel_1?.audio_level || 50);
	const [channel2Volume, setChannel2Volume] = useState<any>(audioDetails?.audio?.channel_2?.audio_level || 50);
	const [micVolume, setMicVolume] = useState<any>(audioDetails?.audio?.mic?.audio_level || 0);
	const [mixerVolume, setMixerVolume] = useState<any>(audioDetails?.audio?.master?.audio_level || 50);
	const [updatedFor, setUpdatedFor] = useState("");
	const [editSlider, setEditSlider] = useState(false);

	const [channel1VolumeText, setChannel1VolumeText] = useState(() => {
		const currentAttendee = userSessions?.attendees?.find(
			(attendee: any) => attendee?.login === props.participant?.email
		);

		return currentAttendee
			? JSON.parse(currentAttendee.preferredChannelNames || "{}").ch1 || ""
			: "";
	});

	const [channel2VolumeText, setChannel2VolumeText] = useState(() => {
		const currentAttendee = userSessions?.attendees?.find(
			(attendee: any) => attendee?.login === props.participant?.email
		);

		return currentAttendee
			? JSON.parse(currentAttendee.preferredChannelNames || "{}").ch2 || ""
			: "";
	});

	const [channel1Icon, setChannel1Icon] = useState(() => {
		const currentAttendee = userSessions?.attendees?.find(
			(attendee: any) => attendee?.login === props.participant?.email
		);

		return currentAttendee
			? JSON.parse(currentAttendee.preferredChannelNames || "{}").id1 || ""
			: "";
	});

	const [channel2Icon, setChannel2Icon] = useState(() => {
		const currentAttendee = userSessions?.attendees?.find(
			(attendee: any) => attendee?.login === props.participant?.email
		);

		return currentAttendee
			? JSON.parse(currentAttendee.preferredChannelNames || "{}").id2 || ""
			: "";
	});

	const { findInstrumentByName } = useInstrumentsAndIcons();
	const [micVolumeText, setMicVolumeText] = useState("");
	const [decibelValue, setDecibelValue] = useState<number>(0);

	const handleDialogClose = (success: boolean, editMode: string) => {
		setEditSlider(false);
	};

	const participantDetails = userSessions.attendees.find(
		(name: any) => name?.email === props.participant?.email
	);

	useEffect(() => {
		const userId = props.participant?.email;
		let storedMap = userCheckedMap || JSON.parse(localStorage.getItem("userCheckedMap") ?? "{}");
		if (!(userId in storedMap)) {
			storedMap[userId] = false;
		}
		setChecked(storedMap[userId]);
		setUserCheckedMap(storedMap);
		localStorage.setItem("userCheckedMap", JSON.stringify(storedMap));

		setInitialMixerDetailsOnReload();

	}, [props.participant?.email]);

	// const prevEmailRef = useRef(props.participant?.email);

	// useEffect(() => {
	// 	if (props.participant?.email !== prevEmailRef.current) {
	// 		console.log("MixerSlider props changed", { props, time: new Date().toISOString() });
	// 		prevEmailRef.current = props.participant?.email; // Update the ref to the current email
	// 	}
	// }, [props.participant?.email]);

	const setInitialMixerDetailsOnReload = () => {
		if (audioDetails) {
			try {
				if (participantDetails) {
					setChannel1Volume(audioDetails?.audio?.channel_1?.audio_level);
					setChannel2Volume(audioDetails?.audio?.channel_2?.audio_level);
					setMicVolume(audioDetails?.audio?.mic?.audio_level);
					setMixerVolume(audioDetails?.audio?.master?.audio_level);
				}
			} catch (error) {
				console.error("Error parsing participantVolumeDetails:", error);
			}
		}
	}

	useEffect(() => {

		const onAudioDetailsMessageReceived = (payload: any) => {
			if (payload.metadata.created_by === props.participant?.email) {
				let db;
				if (payload.metadata.decibel_level >= 30) {
					db = 30;
				} else if (payload.metadata.decibel_level <= -60) {
					db = -60;
				} else {
					db = payload.metadata.decibel_level;
				}
				let percentageValue = (db + 60) / 90;
				setDecibelValue(percentageValue * 100);
			}
		};

		const onUpdatedPreferredChannelName = () => {
			const userSession = localStorage.getItem("userSession");
			if (userSession) {
				setUserSessions(JSON.parse(userSession));
			}
		};


		WebsocketClientUSerStatus.on("audioControl", onMessageReceived);
		WebsocketClientUSerStatus.on("audioDetails", onAudioDetailsMessageReceived);
		WebsocketClientUSerStatus.on("updatedPrefferedChannelName", onUpdatedPreferredChannelName);

		return () => {
			WebsocketClientUSerStatus.off("audioControl", onMessageReceived);
			WebsocketClientUSerStatus.off(
				"audioDetails",
				onAudioDetailsMessageReceived
			);
			WebsocketClientUSerStatus.off("updatedPrefferedChannelName", onUpdatedPreferredChannelName);

		};
	}, []);

	useEffect(() => {
		updatePreferredChannels();
	}, [userSessions]);

	const updatePreferredChannels = () => {
		const attendee = userSessions?.attendees?.find(
			(attendee: any) => attendee?.login === props.participant?.email
		);
		if (attendee) {
			const preferredChannels = JSON.parse(
				attendee?.preferredChannelNames || "{}"
			);
			setChannel1VolumeText(preferredChannels?.ch1 || "");
			setChannel2VolumeText(preferredChannels?.ch2 || "");
			setChannel1Icon(preferredChannels?.id1 || "");
			setChannel2Icon(preferredChannels?.id2 || "");
		}
	};


	const handleMixerVolume = (updated_for: String) => {
		// setMixerVolume(value);

		let audioStatus = mixerVolume === 0 ? "mute" : "unmute";
		let requestMessage = {
			action: "audio_control",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			created_for: props.participant?.email,
			audio: {
				updated_for: updated_for,
				master: {
					audio_status: audioStatus,
					audio_level: mixerVolume,
				},
				channel_1: {
					audio_status:
						audioStatus === "mute"
							? "mute"
							: channel1Volume === 0
								? "mute"
								: "unmute",
					audio_level: channel1Volume,
				},
				channel_2: {
					audio_status:
						audioStatus === "mute"
							? "mute"
							: channel2Volume === 0
								? "mute"
								: "unmute",
					audio_level: channel2Volume,
				},
				mic: {
					audio_status:
						audioStatus === "mute"
							? "mute"
							: micVolume === 0
								? "mute"
								: "unmute",
					audio_level: micVolume,
				},
			},
		};

		WebsocketClientUSerStatus.handleMixerAudioControl(requestMessage);
	};

	const handleMixerChannelName = (preferredNames: String) => {
		let requestMessage = {
			action: "UPDATED_PREFERRED_CHANNEL_NAME",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: userSessions.createdBy,
			created_for: props.participant?.email,
			preferredChannelNames: preferredNames,
		};

		WebsocketClientUSerStatus.handleMixerAudioControl(requestMessage);
	};

	const handleClick = () => {
		const newChecked = !checked;
		setChecked(newChecked);

		const userId = props.participant?.email;

		const storedMap = JSON.parse(localStorage.getItem("userCheckedMap") ?? "{}");

		const updatedMap = { ...storedMap, [userId]: newChecked };

		localStorage.setItem("userCheckedMap", JSON.stringify(updatedMap));
	};

	const onMessageReceived = (payload: any) => {
		if (payload.metadata.created_by === props.participant?.email) {

			if (payload.metadata.audio.updated_for === "master") {
				if (payload.metadata.audio.master.audio_status === "mute") {
					setMixerVolume(0);
					setParticipantAudio(false);
				} else {
					setMixerVolume(payload.metadata.audio.master.audio_level);
					setParticipantAudio(true);
				}
			} else if (payload.metadata.audio.updated_for === "channel_1") {
				if (payload.metadata.audio.channel_1.audio_status === "mute") {
					setChannel1Volume(0);
				} else {
					setChannel1Volume(payload.metadata.audio.channel_1.audio_level);
				}
			} else if (payload.metadata.audio.updated_for === "channel_2") {
				if (payload.metadata.audio.channel_2.audio_status === "mute") {
					setChannel2Volume(0);
				} else {
					setChannel2Volume(payload.metadata.audio.channel_2.audio_level);
				}
			} else if (payload.metadata.audio.updated_for === "mic") {
				if (payload.metadata.audio.mic.audio_status === "mute") {
					setMicVolume(0);
				} else {
					setMicVolume(payload.metadata.audio.mic.audio_level);
				}
			}
		}
	};

	const handleChannel1 = (event: Event, newValue: number | number[]) => {
		setChannel1Volume(newValue);
		setUpdatedFor("channel_1");
	};
	const handleChannel2 = (event: Event, newValue: number | number[]) => {
		setChannel2Volume(newValue);
		setUpdatedFor("channel_2");
	};
	const handleMic = (event: Event, newValue: number | number[]) => {
		setMicVolume(newValue);
		setUpdatedFor("mic");
	};
	const handleSliderChange = (event: Event, newValue: number | number[]) => {
		setMixerVolume(newValue);
		setUpdatedFor("master");
	};

	const getBackgroundGradient = () => {
		if (decibelValue) {
			if (decibelValue < 50) {
				return `linear-gradient(180deg, grey ${100 - decibelValue
					}%, #20E100 ${decibelValue}%)`;
			} else if (decibelValue >= 50 && decibelValue < 80) {
				return `linear-gradient(180deg, grey ${100 - decibelValue}%, #FFB800 ${decibelValue - 50
					}%, #20E100 50%)`;
			} else {
				if (decibelValue === 100) {
					return `linear-gradient(180deg, #FF2E00 ${decibelValue - 90
						}%, #FFB800 30%, #20E100 50%)`;
				} else {
					return `linear-gradient(180deg, grey ${100 - decibelValue
						}%, #FF2E00 ${decibelValue - 90}%, #FFB800 30%, #20E100 50%)`;
				}
			}
		} else {
			return "grey !important";
		}
	};

	const formatNameWithMaxLength = (
		name: string | undefined,
		maxChars: number
	): string => {
		return name && name.length > maxChars
			? name.substring(0, maxChars) + "..."
			: name || "";
	};

	const handleChange = (event: any) => {
		setSelectedPreset(event.target.value);
	};

	const handlePresetDialogClose = () => {
		setOpenPresetDialogBox(false);
	}

	const getParticipantDisplayName = (participantDetails: any, user: any) => {
		const firstName = formatNameWithMaxLength(
			participantDetails?.firstName,
			17
		);
		const lastName = formatNameWithMaxLength(participantDetails?.lastName, 17);
		const nickname = `@${formatNameWithMaxLength(
			participantDetails?.nickname,
			17
		)}`;

		if (participantDetails?.userProfileId === user?.userProfileId) {
			return `${firstName} ${lastName}`;
		}

		switch (participantDetails?.userPrivacySettingsDTO?.identityPermission) {
			case "Name is not visible. Instead just show nickname":
				return nickname;

			case "Name is visible to all":
				return `${firstName} ${lastName}`;

			case "Name is visible only to my connections. Other see nickname":
				return participantDetails?.connected
					? `${firstName} ${lastName}`
					: nickname;

			default:
				return `${firstName} ${lastName}`;
		}
	};

	const handleOpenPresetDialog = (type: string) => {
		setOpenPresetDialogBox(true);
		setType(type);
	}


	const videoState = useVideoTrack(props.participantId);
	const isEditButtonEnabled = participantDetails?.userProfileId === user?.userProfileId;

	return (
		<>
			<Grid sx={{ height: "100%" }}>
				<Box sx={{ height: "25%", textAlign: "center" }}>
					<Grid container direction={"column"} sx={{ py: 1 }}>
						<Grid item
							sx={{
								padding: videoState.isOff ? '12px 24px' : '4px', position: 'relative',
								display: 'flex', alignItems: 'center',
								justifyContent: 'center', flexDirection: 'column',
							}}
						>
							{videoState.isOff ? <div style={{ position: 'relative' }}>
								<MixerSliderMuteIconWrapper isInsideAvatar>
									{getMixerSliderMuteIcon(participantAudio, true)}
								</MixerSliderMuteIconWrapper>
								{getUserAvatar(participantDetails)}
							</div> : <Tile id={props.participantId} isInsideMixer />}
							{videoState.isOff &&
								<Typography variant="h6" sx={{ color: "white", marginTop: 1, marginBottom: 1 }}>
									{getParticipantDisplayName(participantDetails, user)}
								</Typography>
							}
						</Grid>
					</Grid>
				</Box>

				{/* Do not show preset for now. while enable(Show) this, set above box hight to 25% and  below to 60%*/}
				<Box sx={{ height: "10%", mb: 2, mt: 1, display: 'none', pointerEvents: 'none' }}>
					<Grid container xs={12} md={12} sx={{ padding: "4px" }}>
						<Grid item xs={checked ? 5 : 12} >
							<Typography className="presetText">Preset</Typography>
							<select
								id="preset-dropdown"
								value={selectedPreset}
								onChange={handleChange}
								className="preset-dropdown"
							>
								{presets.map((preset, index) => (
									<option key={index} value={preset}>
										{preset}
									</option>
								))}
							</select>
						</Grid>
						{checked && (
							<>
								<Grid item xs={3} className="centerAligned">
									<Typography className="presetText">Update</Typography>
									<img src={UpdateIcon} onClick={() => handleOpenPresetDialog("update")} style={{ textAlign: "center", cursor: "pointer" }} />
								</Grid>
								<Grid item xs={2} className="centerAligned">
									<Typography className="presetText">Delete</Typography>
									<img src={DeleteIcon} onClick={() => handleOpenPresetDialog("delete")} style={{ textAlign: "center", cursor: "pointer" }} />
								</Grid>
								<Grid item xs={2} className="centerAligned">
									<Typography className="presetText">New</Typography>
									<img src={NewIcon} onClick={() => handleOpenPresetDialog("new")} style={{ textAlign: "center", cursor: "pointer" }} />
								</Grid>
							</>
						)}
					</Grid>
					<Grid container xs={12} sx={{ borderBottom: "1px solid black", padding: "5px" }}></Grid>
				</Box>

				<Box sx={{ height: "70%", mt: 3 }}>
					<Grid
						container
						sx={{ height: "100%", position: "relative", display: "flex", alignItems: checked ? "center" : "initial" }}
					>
						{checked && (
							<>
								{/* Mixer actions */}
								<Grid container
									xs={12}
									md={12}
									sx={{ backgroundColor: "#06233E", borderRadius: '6px', mt: 2 }}
								>
									<Grid
										xs={3}
										container
										rowGap={1}
										direction={"column"}
										justifyContent={"center"}
										alignItems={"center"}
										sx={{ borderRight: "2px solid #093969", padding: "12px 0" }}
									>
										<Grid item className="mixerImg">
											<img
												src={findInstrumentByName(channel1VolumeText)?.icon}
												alt={channel1VolumeText}
												style={{
													height: "18px",
													filter: "brightness(0) invert(1)",
												}}
											/>
										</Grid>
										<Grid item sx={{ textAlign: "center" }}>
											<Tooltip title={channel1VolumeText}>
												<Typography
													className="secondaryText"
													variant="caption"
													sx={{
														color: "white",
														textOverflow: "ellipsis",
														display: "block",
														overflow: "hidden",
														whiteSpace: "nowrap"
													}}
												>
													{channel1VolumeText ? channel1VolumeText : "CH1"}
												</Typography>
											</Tooltip>
										</Grid>
									</Grid>

									<Grid
										xs={3}
										container
										rowGap={1}
										direction={"column"}
										justifyContent={"center"}
										alignItems={"center"}
										sx={{ borderRight: "2px solid #093969", padding: "12px 0" }}
									>
										<Grid item className="mixerImg">
											<img
												src={findInstrumentByName(channel2VolumeText)?.icon}
												alt={channel2VolumeText}
												style={{
													height: "18px",
													filter: "brightness(0) invert(1)",
												}}
											/>
										</Grid>
										<Grid item sx={{ textAlign: "center" }}>
											<Tooltip title={channel2VolumeText}>
												<Typography
													className="secondaryText"
													variant="caption"
													sx={{
														color: "white",
														textOverflow: "ellipsis",
														display: "block",
														overflow: "hidden",
														whiteSpace: "nowrap"
													}}
												>
													{channel2VolumeText ? channel2VolumeText : "CH2"}
												</Typography>
											</Tooltip>
										</Grid>
									</Grid>

									<Grid
										xs={3}
										container
										rowGap={1}
										direction={"column"}
										justifyContent={"center"}
										alignItems={"center"}
										sx={{ borderRight: "2px solid #093969", padding: "12px 0" }}
									>
										<Grid item className="mixerImg">
											<img src={Mic} alt="Mic" style={{ height: "18px" }} />
										</Grid>
										<Grid item sx={{ textAlign: "center" }}>
											<Tooltip title={micVolumeText}>
												<Typography
													className="secondaryText"
													variant="caption"
													sx={{
														color: "white",
														display: "block",
														textOverflow: "ellipsis",
														overflow: "hidden",
													}}
												>
													{micVolumeText ? micVolumeText : "Mic"}
												</Typography>
											</Tooltip>
										</Grid>
									</Grid>

									<Grid
										xs={3}
										container
										rowGap={1}
										direction={"column"}
										justifyContent={"center"}
										alignItems={"center"}
										sx={{
											cursor: isEditButtonEnabled ? "pointer" : "not-allowed",
											padding: "12px 0",
											filter: isEditButtonEnabled ? "none" : "grayscale(1)",
											pointerEvents: isEditButtonEnabled ? "auto" : "none",
										}}
										onClick={() => isEditButtonEnabled ? setEditSlider(true) : null}
									>
										<Grid item className="mixerImg">
											<img
												src={EditIcon}
												alt="Pencil"
												style={{ height: "24px", margin: "-5px" }}
											/>
										</Grid>
										<Grid item sx={{ textAlign: "center" }}>
											<Tooltip title={'Edit Channel Name and Icon'}>
												<Typography
													className="secondaryText"
													variant="caption"
													sx={{
														color: "white",
														textOverflow: "ellipsis",
														display: "block",
														overflow: "hidden",
													}}
												>
													Edit
												</Typography>
											</Tooltip>
										</Grid>
									</Grid>
								</Grid>

								{/* Mixer Sliders  */}
								<Grid container
									xs={12}
									md={12}
									sx={{ backgroundColor: "#06233E", borderRadius: '6px', height: "60%", marginTop: 1, marginBottom: 2 }}
								>
									<Grid
										xs={3}
										container
										rowGap={1}
										direction={"column"}
										sx={{ alignItems: "center", borderRight: "2px solid #093969", padding: "24px 0" }}
									>
										<Grid
											item
											className="secondarySlider"
											sx={{
												"& .MuiSlider-thumb": {
													height: "20px",
													width: "20px",
													background: `linear-gradient(143.33deg, #8E99A7 -4.78%, #434D59 63.57%)`,
													//border: "2px solid #8EBAFB",
													boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
												},
												"& .MuiSlider-mark": {
													width: "15px",
													backgroundColor: "#000000",
												},
												"& .MuiSlider-rail": {
													width: "5px",
													background: "#4D5EB9",
												},
												"& .MuiSlider-markLabel": {
													color: "#888888",
												},
												"& .MuiSlider-track": {
													width: "5px", // Fixed width for track
													border: "none",
													backgroundColor: "transparent",
												},
											}}
										>
											<Slider
												size="small"
												aria-label="Volume"
												orientation="vertical"
												valueLabelDisplay="auto"
												value={channel1Volume}
												// onChange={(event, value) => setChannel1Volume(value)}
												onChange={handleChannel1}
												onChangeCommitted={() => {
													handleMixerVolume("channel_1");
												}}
												onKeyDown={(event) => {
													event.preventDefault();
												}}
											/>
										</Grid>
									</Grid>

									<Grid
										xs={3}
										container
										rowGap={1}
										direction={"column"}
										sx={{ alignItems: "center", borderRight: "2px solid #093969", padding: "24px 0" }}
									>
										<Grid
											item
											className="secondarySlider"
											sx={{
												"& .MuiSlider-thumb": {
													height: "20px",
													width: "20px",
													background: `linear-gradient(143.33deg, #8E99A7 -4.78%, #434D59 63.57%)`,
													//border: "2px solid #8EBAFB",
													boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
												},
												"& .MuiSlider-mark": {
													width: "15px",
													backgroundColor: "#000000",
												},
												"& .MuiSlider-rail": {
													width: "5px",
													background: "#4D5EB9",
												},
												"& .MuiSlider-markLabel": {
													color: "#888888",
												},
												"& .MuiSlider-track": {
													width: "5px", // Fixed width for track
													border: "none",
													backgroundColor: "transparent",
												},
											}}
										>
											<Slider
												size="small"
												aria-label="Volume"
												orientation="vertical"
												valueLabelDisplay="auto"
												value={channel2Volume}
												// onChange={(event, value) => setChannel2Volume(value)}
												onChange={handleChannel2}
												onChangeCommitted={() => {
													handleMixerVolume("channel_2");
												}}
												onKeyDown={(event) => {
													event.preventDefault();
												}}
											/>
										</Grid>
									</Grid>

									<Grid
										xs={3}
										container
										rowGap={1}
										direction={"column"}
										sx={{ alignItems: "center", borderRight: "2px solid #093969", padding: "24px 0" }}
									>
										<Grid
											className="secondarySlider"
											item
											sx={{
												"& .MuiSlider-thumb": {
													height: "20px",
													width: "20px",
													background: `linear-gradient(143.33deg, #8E99A7 -4.78%, #434D59 63.57%)`,
													//border: "2px solid #8EBAFB",
													boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
												},
												"& .MuiSlider-mark": {
													width: "15px",
													backgroundColor: "#000000",
												},
												"& .MuiSlider-rail": {
													width: "5px",
													background: "#4D5EB9",
												},
												"& .MuiSlider-markLabel": {
													color: "#888888",
												},
												"& .MuiSlider-track": {
													width: "5px", // Fixed width for track
													border: "none",
													backgroundColor: "transparent",
												},
											}}
										>
											<Slider
												size="small"
												aria-label="Volume"
												orientation="vertical"
												valueLabelDisplay="auto"
												value={micVolume}
												// onChange={(event, value) => setMicVolume(value)}
												onChange={handleMic}
												onChangeCommitted={() => {
													handleMixerVolume("mic");
												}}
												onKeyDown={(event) => {
													event.preventDefault();
												}}
											/>
										</Grid>
									</Grid>

									<Grid
										xs={3}
										container
										rowGap={1}
										direction={"column"}
										sx={{ cursor: "pointer", alignItems: "center", padding: "24px 0" }}
									>
										<Grid className="primarySlider">
											<Slider
												sx={{
													"& .MuiSlider-track": {
														width: "10px", // Fixed width for track
														border: "none",
														backgroundColor: "transparent",
													},
													"& .MuiSlider-rail": {
														width: "10px", // Fixed width for rail
														background: getBackgroundGradient(),
														borderRadius: "4px", // Optional: add border radius
														opacity: 1,
													},
													"& .MuiSlider-thumb": {
														height: "30px",
														width: "30px",
														background: "#ff702a",
														//border: "2px solid #8EBAFB",
														boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
													},
													"& .MuiSlider-mark": {
														width: "15px",
														backgroundColor: "#000000",
													},
													"& .MuiSlider-markLabel": {
														color: "#888888",
													},
												}}
												aria-label="Volume"
												orientation="vertical"
												// marks={mixerRanges}
												onChangeCommitted={(event: any, value) =>
													handleMixerVolume("master")
												}
												// onChange={(event, value) => setMixerVolume(value)}
												value={mixerVolume}
												onChange={handleSliderChange}
												min={0}
												max={100}
												valueLabelDisplay="auto"
											/>
										</Grid>
									</Grid>

								</Grid>
								{/* Mixer Slider Ends  */}

							</>
						)}

						<Grid container alignContent={'center'} justifyContent={"center"}>
							{!checked &&
								<Grid
									container
									className="primarySliderContainer"
									direction={"column"}
									rowGap={checked ? 1 : 1}
									xs={checked ? 12 : 12}
									md={checked ? 4 : 12}
									sx={{
										height: "80%",
										alignItems: "center",
										// pt: 2,
										// mt: checked ? 0 : 2
									}}
								>
									<Grid className="primarySlider">
										<Slider
											sx={{
												"& .MuiSlider-track": {
													width: "10px", // Fixed width for track
													border: "none",
													backgroundColor: "transparent",
												},
												"& .MuiSlider-rail": {
													width: "10px", // Fixed width for rail
													background: getBackgroundGradient(),
													borderRadius: "4px", // Optional: add border radius
													opacity: 1,
												},
												"& .MuiSlider-thumb": {
													height: "30px",
													width: "30px",
													background: "#ff702a",
													//border: "2px solid #8EBAFB",
													boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
												},
												"& .MuiSlider-mark": {
													width: "15px",
													backgroundColor: "#000000",
												},
												"& .MuiSlider-markLabel": {
													color: "#888888",
												},
											}}
											aria-label="Volume"
											orientation="vertical"
											// marks={mixerRanges}
											onChangeCommitted={(event: any, value) =>
												handleMixerVolume("master")
											}
											// onChange={(event, value) => setMixerVolume(value)}
											value={mixerVolume}
											onChange={handleSliderChange}
											min={0}
											max={100}
											valueLabelDisplay="auto"
										/>
									</Grid>
								</Grid>
							}
							<Grid className="showIconButton">
								<IconButton
									aria-label="show"
									className="showIcon"
									onClick={handleClick}

									sx={{
										height: "20px",
										width: "20px",
										backgroundColor: "#CFD9DF",
										// ml: checked ? "0" : 0, // Push to the right when checked
										marginTop: checked ? "-24px" : "8px", // Push down when unchecked
									}}
								>
									{checked ? (
										<KeyboardArrowLeftIcon />
									) : (
										<KeyboardArrowRightIcon />
									)}
								</IconButton>
							</Grid>
						</Grid>
						<MixerSliderEdit
							editSlider={editSlider}
							handleClose={handleDialogClose}
							participantName={
								`${formatNameWithMaxLength(
									participantDetails?.firstName,
									17
								)}` +
								` ${formatNameWithMaxLength(
									participantDetails?.lastName,
									17
								)}`
							}
							// volumeText = {{channel1VolumeText: channel1VolumeText, channel2VolumeText: channel2VolumeText, micVolumeText: micVolumeText}}
							setVolumeText={{
								channel1VolumeText: channel1VolumeText,
								channel2VolumeText: channel2VolumeText,
								setChannel1VolumeText: setChannel1VolumeText,
								setChannel2VolumeText: setChannel2VolumeText,
								setChannel1Icon: setChannel1Icon,
								setChannel2Icon: setChannel2Icon,
								setMicVolumeText: setMicVolumeText,
							}}
							handleMixerChannelName={handleMixerChannelName}
						/>
						<PresetDialogBox openPresetDialogBox={openPresetDialogBox} type={type} handlePresetDialogClose={handlePresetDialogClose} />
					</Grid>
				</Box>
			</Grid>
		</>
	);
};

const areEqual = (prevProps: any, nextProps: any) => {
	const emailChanged = prevProps.participant?.email !== nextProps.participant?.email;
	const idChanged = prevProps.participantId !== nextProps.participantId;
	if (emailChanged) {
		console.log("MixerSlider props changed", { nextProps, time: new Date().toISOString() });
	}

	return !emailChanged && !idChanged;
}

export default memo(MixerSlider, areEqual);

