import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { Button, Tooltip, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useUser } from "../../../context/UserContext";
import Leave from "../../../images/phone-leave.svg";
import { useFullscreenModeContext } from "../../../context/FullscreenModeContext";
import WebsocketClientUSerStatus from "../../../services/WebsocketClientUSerStatus";
import { useBroadcastingStatusContext } from "../../../context/BroadcastingStatusContext";
import { useDaily } from "@daily-co/daily-react";
import WebsocketMetricsClient from "../../../services/WebsocketMetricsClient";
import { Constants } from "../../../services/constants";
import ActionDialog from "../../reusable-components/ActionDialog";
import { Heading } from "../../Daily/LiveStreamingDialog/EndLiveStreamDialog";
import { useDailyAppRoom } from "../../Daily/useDailyGlobalRoom";
import i18n from "../../../l10n/strings.json";

const useStyles = makeStyles(() =>
    createStyles({
        button: {
            background: "#E11E1E !important",
            color: "white",
            "&:hover": {
                background: "#E11E1E !important",
            },
            padding: "5px 15px",
            borderRadius: "6px",
        },
    })
);

export default function EndCallButton(props: {
    className?: string, broadcastResponse?: any, streamStartedBy?: any, onLeaveCall?: Function, isRecordingStarted?: boolean,
    isLiveStreaming?: boolean
}) {
    const twilioSessionId = localStorage.getItem("twilioSessionId") || "";
    const navigate = useNavigate();
    const user = useUser();
    const recordingStatus = localStorage.getItem("userSession") ? JSON.parse(localStorage.getItem("userSession") || "{}").recordingStatus : "";
    const dailyRoomObj = useDaily();
    const { toggleSendingAudioFromDevice } = useDailyAppRoom();

    const classes = useStyles();
    const { isFullscreenMode, setIsFullscreenMode, setIsTabHidden, isTabHidden } = useFullscreenModeContext();
    const { liveStreamingStartedBy, recordingDetails, setIsLiveStreaming } =
        useBroadcastingStatusContext();
    const storedUserSession = localStorage.getItem("userSession");
    const userSession = storedUserSession ? JSON.parse(storedUserSession) : null;
    const [open, setOpen] = useState(false);
    const { allParticipantsCount } = useDailyAppRoom();

    const recordingStartedByUser = recordingDetails?.recordingStarted  && (recordingDetails.recordingStartedBy === user?.email || 
        recordingDetails.recordingStartedBy === user?.id
    );

    const [leaveSessionDialogContent, setLeaveSessionDialogContent] = useState({
        heading: "",
        subHeading1: "",
        subHeading2: "",
        buttonText: "",
        type: ""
    });


    useEffect(() => {
        const onMessageReceived = (payloadData: any) => {
            if (props?.streamStartedBy === user?.email) {
                WebsocketClientUSerStatus.disconnectWebsocketConnection(payloadData, user);
                WebsocketClientUSerStatus.disconnectWebsocket();
            }
        };
        WebsocketClientUSerStatus.on("leaveSession", onMessageReceived);
        return () => {
            WebsocketClientUSerStatus.off("leaveSession", onMessageReceived);
        };
    }, []);
	const handleLeaveClick = () => {
        if(recordingStartedByUser || liveStreamingStartedBy === user?.id) {
            setOpen(true);
        } else {
            if (isFullscreenMode) {
				document.exitFullscreen();
				setIsFullscreenMode(false);
			}
			leaveRoom();
        }
	  };
	
	  const handleConfirmLeave = () => {
		setOpen(false);
		if (isFullscreenMode) {
			document.exitFullscreen();
			setIsFullscreenMode(false);
		}
		leaveRoom(); 
	  };
	
	  const handleCancelLeave = () => {
		setOpen(false); 
	  };

    useEffect(() => {
        if (recordingStartedByUser) {
            setLeaveSessionDialogContent((prevContent) => {
                return {
                    ...prevContent,
                    heading: i18n.jamSession.dialog.recording.heading,
                    subHeading1: i18n.jamSession.dialog.recording.subHeading1,
                    subHeading2: i18n.jamSession.dialog.recording.subHeading2,
                    buttonText: i18n.jamSession.dialog.recording.buttonText,
                    type: i18n.jamSession.dialog.recording.type
                }
            });
        } else if (props?.isLiveStreaming && liveStreamingStartedBy === user?.id) {
            setLeaveSessionDialogContent((prevContent) => {
                return {
                    ...prevContent,
                    heading: i18n.jamSession.dialog.liveStream.heading,
                    subHeading1: i18n.jamSession.dialog.liveStream.subHeading1,
                    subHeading2: i18n.jamSession.dialog.liveStream.subHeading2,
                    buttonText: i18n.jamSession.dialog.liveStream.buttonText,
                    type: i18n.jamSession.dialog.liveStream.type
                }
            });
        } else {
            setLeaveSessionDialogContent((prevContent) => {
                return {
                    ...prevContent,
                    heading: i18n.jamSession.dialog.default.heading,
                    subHeading1: "",
                    subHeading2: i18n.jamSession.dialog.default.subHeading2,
                    buttonText: i18n.jamSession.dialog.default.buttonText,
                    type: i18n.jamSession.dialog.default.type
                }
            });
        }
    }, [open]);

    const leaveRoom = () => {
        handleRecording();
        if (isTabHidden === false) {
            setIsTabHidden(isTabHidden)
        }
        else {
            setIsTabHidden(false);
        }
        isFullscreenMode && setIsFullscreenMode(false);
        localStorage.setItem("checked", JSON.stringify(false));
        localStorage.removeItem("userCheckedMap");
        if (document.fullscreenElement) {
            document.exitFullscreen().catch((err) => {
                console.error("Failed to exit full-screen mode:", err);
            });
        }
        if (props?.streamStartedBy === user?.id) {
            setIsFullscreenMode(false);
            // setIsTabHidden(false);
            // localStorage.removeItem("session_attendees");
            // const sessionAttendees = JSON.parse(localStorage.getItem("session_attendees") || "");
            const storedAttendees = localStorage.getItem("session_attendees");
            var sessionStopMessage = {
                action: "session_stop",
                timestamp: new Date().toISOString(),
                session_id: twilioSessionId,
                created_by: user.id,
                unsubscribe:
                    dailyRoomObj && Object.keys(dailyRoomObj?.participants()).length === 0
                        ? true
                        : false,
            };

            let liveStreamMsg = {
                action: "live_stream_status",
                timestamp: new Date(),
                session_id: twilioSessionId,
                created_by: user.id,
                dailyLiveStreamStatus: "STOP",
            };
            userSession.dailyLiveStreamStatus = false;
            WebsocketMetricsClient.disconnectWebsocketConnection();
            // WebsocketMetricsClient.disconnectWebsocketConnection();
            toggleSendingAudioFromDevice({ userId: user.id, dailySessionId: twilioSessionId, cmd: "STOP" });
            WebsocketClientUSerStatus.handleLiveStream(liveStreamMsg);
            WebsocketClientUSerStatus.handleLeaveSession(sessionStopMessage);
            WebsocketClientUSerStatus.onLeaveSession(twilioSessionId, user);
            setIsLiveStreaming(false);
        } else {
            const sessionStopMessage = {
                action: "session_stop",
                timestamp: new Date().toISOString(),
                session_id: twilioSessionId,
                created_by: user.id,
                unsubscribe:
                    dailyRoomObj && Object.keys(dailyRoomObj?.participants()).length === 0
                        ? true
                        : false,
            };
            userSession.dailyLiveStreamStatus = true;
            WebsocketClientUSerStatus.onLeaveSession(twilioSessionId, user);
            WebsocketClientUSerStatus.handleLeaveSession(sessionStopMessage);
        }
        setIsFullscreenMode(false);
        setIsTabHidden(false);
        if (isFullscreenMode) {
            document.exitFullscreen()
        }
        props.onLeaveCall && props.onLeaveCall();
        navigate("/ratejamsession");
    };

    const handleRecording = () => {
        if (recordingDetails?.recordingType === "onlyaudio" && recordingDetails?.recordingStartedBy === user.email && recordingStatus) {
            let message = {
                action: "audio_recording",
                timestamp: new Date().toISOString(),
                session_id: twilioSessionId,
                created_by: user.id,
                recordingStatus: Constants.RecordingCmd.STOP,
            };

            WebsocketClientUSerStatus.handleRecordingControl(message);
        }
    }

    return (
        <>
            <Tooltip title="Leave Session">
                <Button
                    onClick={handleLeaveClick}
                    className={clsx(classes.button, props.className)}
                    data-cy-disconnect
                    startIcon={<img src={Leave} alt="leave session" />}
                >
                    Leave
                </Button>
            </Tooltip>

            <ActionDialog
                open={open}
                buttonsAlignment="right"
                showCloseIcon={false}
                handleClose={handleCancelLeave}
                dialogWidth= {(props?.isRecordingStarted || props?.isLiveStreaming) ? "600px" : "480px"}
                title={leaveSessionDialogContent?.heading}
                titleStyle={{
                    color: "#000",
                    fontSize: "1.375rem",
                    fontWeight: "500",
                }}
                titleAlignment={leaveSessionDialogContent?.type === "default" ? "center" : "left"}
                actionButtons={
                    <>
                        <Button
                            sx={{
                                width: "7.8125rem",
                                height: "2.5rem",
                                borderRadius: "2.5rem",
                            }}
                            className="cancel-button"
                            size="large"
                            variant="outlined"
                            onClick={handleCancelLeave}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{
                                width: (props?.isRecordingStarted || props?.isLiveStreaming) ? "12.5625rem" : "7.8125rem",
                                height: "2.5rem",
                                borderRadius: "1.25rem",
                                backgroundColor: "rgba(235, 42, 0, 1)"
                            }}
                            className="submit-button"
                            size="large"
                            variant="contained"
                            onClick={handleConfirmLeave}
                        >
                            {leaveSessionDialogContent?.buttonText}
                        </Button>
                    </>
                }
            >
                <Typography sx={{ fontSize: "1rem", fontWeight: "400", color: "#FD4B24" }}>
                    {leaveSessionDialogContent?.subHeading1}
                </Typography>
                <Typography sx={{ fontSize: "1rem", fontWeight: "400", color: "#4D4C4F" }}>
                    {leaveSessionDialogContent?.subHeading2}
                </Typography>
                {leaveSessionDialogContent.type === "liveStreaming" && (
                    <Heading sx={{ mt: 2 }}>
                        Live audience: <span style={{ fontWeight: 400, color: "#000", fontSize: "1.125rem" }}>{allParticipantsCount?.hidden}</span>
                    </Heading>
                )}
            </ActionDialog>
        </>
    );
}