import { memo, useState, useEffect, useRef, useLayoutEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/styles";
import { useWakeLock } from "react-screen-wake-lock";
import { useUser } from "../../context/UserContext";
import { SessionInterface } from "../../types/types";
import { clearLocalstorage, stopSessionRecording } from "../../utils";
import { useFullscreenModeContext } from "../../context/FullscreenModeContext";
import SessionHoldDialog from "../../dialogBox/SessionHoldDialog";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";
import DailyRoom from "../Daily/Daily";
import { DailyProvider, useDaily, useRoom } from "@daily-co/daily-react";
import { DailyCall } from '@daily-co/daily-js';
import { RecoilRoot } from "recoil";
import { STATE_ERROR, STATE_IDLE, STATE_JOINED, STATE_JOINING, STATE_LEAVING } from "../Daily/Constants";
import { Alert, AlertTitle, Button, Snackbar } from "@mui/material";
import SuccessIcon from "../../images/tick-square.svg";
import ErrorIcon from "../../images/danger.svg";
import getErrorInfo from "../../types/errorTypes";
import DeviceError from "./DeviceError";
import { normalizeAudioControls } from "../Mixer/utils";



type RouterState = {
	session: SessionInterface;
	token: string;
	username: string;
	twilioSessionId: string;
};

type VAppType = {
	setCallObject: Function;
}

type CustomAlertProps = {
	alertMsg: string;
	showAlert: boolean;
	handleAlertClose: () => void;
	alertStatus: boolean;
}

const CustomAlert: React.FC<CustomAlertProps> = (props) => {
	return (
		props.alertMsg !==
			"Time-out! Sessions are limited to four hours. Please rejoin the session or schedule a new session to get back to jamming." ? (
			<Snackbar
				sx={{
					"&.MuiSnackbar-anchorOriginTopCenter": {
						top: "70px",
					},
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={props.showAlert}
				autoHideDuration={30000}
				onClose={props.handleAlertClose}
			>
				<Alert
					onClose={props.handleAlertClose}
					icon={
						props.alertStatus ? (
							<img src={SuccessIcon} alt="error-icon" />
						) : (
							<img
								src={ErrorIcon}
								alt="error-icon"
								style={{ marginRight: "10px" }}
							/>
						)
					}
					sx={{
						backgroundColor: "#FFFFFF",
						boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
						borderRadius: "6px",
						fontSize: "1.5rem",
						fontWeight: "500",
						color: "black",
					}}
				>
					<AlertTitle
						sx={{
							fontSize: "1.125rem",
							fontWeight: "500",
							color: "#000000",
							margin: "auto",
							padding: "5px",
							// justifyContent: "center !important",
						}}
					>
						{props.alertMsg}
					</AlertTitle>
				</Alert>
			</Snackbar>
		) : (
			<Snackbar
				sx={{
					"&.MuiSnackbar-anchorOriginTopCenter": {
						top: "70px",
					},
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={props.showAlert}
				onClose={props.handleAlertClose}
			>
				<Alert
					onClose={props.handleAlertClose}
					icon={
						props.alertStatus ? (
							<img src={SuccessIcon} alt="error-icon" />
						) : (
							<img
								src={ErrorIcon}
								alt="error-icon"
								style={{ marginRight: "10px", marginBottom: "60px" }}
							/>
						)
					}
					sx={{
						backgroundColor: "#FFFFFF",
						boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
						borderRadius: "6px",
						fontSize: "1.5rem",
						fontWeight: "500",
						color: "black",
					}}
				>
					<AlertTitle
						sx={{
							fontSize: "1.125rem",
							fontWeight: "500",
							color: "#000000",
							margin: "auto",
							padding: "5px",
							// justifyContent: "center !important",
						}}
					>
						{props.alertMsg}
						<div style={{ textAlign: "center", marginTop: "10px" }}>
							<Button
								variant="contained"
								size="medium"
								onClick={props.handleAlertClose}
							>
								Ok
							</Button>
						</div>
					</AlertTitle>
				</Alert>
			</Snackbar>
		)
	);
}

function VApp({ setCallObject }: VAppType) {
	const navigate = useNavigate();
	const [startTime, setStartTime] = useState();
	const { request } = useWakeLock();
	// Here we would like the height of the main container to be the height of the viewport.
	// On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
	// not the viewport. This looks bad when the mobile browsers location bar is open.
	// We will dynamically set the height with 'window.innerHeight', which means that this
	// will look good on mobile browsers even after the location bar opens or closes.
	// const height = useHeight();
	// Session
	const token = localStorage.getItem("sessionId");
	const location = useLocation();
	const user = useUser();
	const locationState = location.state as RouterState | undefined;
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	const { isFullscreenMode, isTabHidden } = useFullscreenModeContext();
	const [isSessionHold, setIsSessionHold] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [alertMsg, setAlertMsg] = useState<string>("");
	const timeoutRef = useRef<any>(undefined);
	const [deviceError, setDeviceError] = useState(false);
	const [errorCode, setErrorCode] = useState("");
	const [deviceId, setDeviceId] = useState("");
	const [userData, setUserData] = useState<any>(null);
	const [loading, setLoading] = useState(true);
	const [deviceData, setDeviceData] = useState<any>();
	const [errorMessage, setErrorMessage] = useState("");
	const [containerheight, setContainerheight] = useState(`calc(100vh - 72px)`)
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");
	const dailyToken = location?.state?.token;
	const dailySession = location?.state?.session;
	const username = location?.state?.username;
	const room = useRoom();
	const [dailyRoomState, setDailyRoomState] = useState(STATE_IDLE);
	const showDailyCall = [STATE_JOINING, STATE_JOINED, STATE_ERROR].includes(dailyRoomState);
	const dailyRoomObj = useDaily();

	const errorInfo = errorCode !== "102" ? getErrorInfo(errorCode) : null;

	useEffect(() => {
		if (dailySession?.dailyRoomUrl && dailyToken) {
			setDailyRoomState(STATE_JOINING);
		}
	}, [dailySession, dailyToken]);

	useEffect(() => {
		if (dailyRoomState === STATE_LEAVING || dailyRoomState === STATE_ERROR) {
			console.log("connection timeout", dailyRoomState);
			if (dailyRoomState === STATE_ERROR) {
				setErrorCode("408"); // changed this code from 401 to connection error 408
				setErrorMessage(linkifyMessage("This could be due to a connection error or the session exceeding the 4-hour limit. Please reconnect or contact us if the issue persists."));
				setDeviceError(true);
			}
			leaveSession();
			navigate("/jamsession");
		}
	}, [dailyRoomState, dailyRoomObj]);

	useLayoutEffect(() => {
		getDeviceId();
	}, []);

	useEffect(() => {
		if (deviceId && errorCode === "102") {
			fetch(`${process.env.REACT_APP_BASE_URL}/user/get-user-by-device-id`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify([{ deviceId: deviceId }]),
			})
				.then((response) => {
					if (response.status >= 400) {
						console.log("Failed to fetch API");
						setLoading(false);
					} else {
						return response.json();
					}
				})
				.then((result) => {
					setUserData(result);
					setLoading(false);
				})
				.catch((error) => {
					console.error("Error fetching user data:", error);
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	}, [deviceId, errorCode, token]);

	useEffect(() => {
		let user = localStorage.getItem("user");
		if (user) {
			try {
				const userDetails = JSON.parse(user);
				if (
					(!userDetails.firstName ||
						!userDetails.lastName ||
						!userDetails.location) && !isAdmin
				) {
					navigate("/socialProfileNew", {
						state: {
							email: userDetails.email,
							password: "",
						},
					});
				}
			} catch (e) {
				console.error("Failed to parse user data:", e);
			}
		}
	}, [navigate]);


	const getDeviceId = () => {
		const requestBody = {
			userId: locationState?.session?.createdBy,
		};

		fetch(`${process.env.REACT_APP_BASE_URL}/api/user-device/getDevice`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(requestBody),
		})
			.then((res) => {
				if (res.status >= 400) {
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					setDeviceData(data);
				}
			});
	};

	const Container = styled("div")({
		display: "grid",
		gridTemplateRows: "1fr auto",
	});

	const Main = styled("main")(({ theme }: { theme: Theme }) => ({
		// overflow: "hidden",
		// paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
		background: "black",
		[theme.breakpoints.down("sm")]: {
			paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
		},
	}));

	const linkifyMessage = (errorMessage: string) => {
		const contactUsText = "contact us";
		const contactUsLink = `<a href="${process.env.REACT_APP_BASE_URL_FRONTEND}/helpAndSupport" target="_blank" rel="noopener noreferrer" style="color: blue;">contact us</a>`; // Update with your contact link

		if (errorMessage.includes(contactUsText)) {
			return errorMessage.replace(contactUsText, contactUsLink);
		}

		return errorMessage;
	};

	const onErrorReceived = (payLoad: any) => {
		setErrorCode(payLoad?.metadata?.error_code);
		setDeviceId(payLoad?.metadata?.created_by);
		const message = payLoad?.metadata?.error;
		setErrorMessage(linkifyMessage(message));
		setDeviceError(true);
	};

	useEffect(() => {
		WebsocketClientUSerStatus.on("errorCode", onErrorReceived);
		return () => {
			WebsocketClientUSerStatus.off("errorCode", onErrorReceived);
		};
	}, []);

	useEffect(() => {
		let isRoomInstantiated = false;
		if (dailyRoomObj && room) {
			isRoomInstantiated = true;
		}

		if (isRoomInstantiated && dailyRoomObj) {
			const participants = dailyRoomObj.participants();
			if (Object.keys(participants).length > 0) {
				Object?.entries(participants).forEach(([participantKey, participant]) => {
					if (participantKey !== "local") {
						let participantDeviceStatusMsg = {
							action: "device_status",
							timestamp: new Date().toISOString(),
							session_id: twilioSessionId,
							created_by: user.id,
							created_for: participant?.user_name,
						};
						WebsocketClientUSerStatus.handleAudioControl(
							participantDeviceStatusMsg
						);
					}
				});
			}
		}
	}, [room]);

	useEffect(() => {
		let isMsgReceived = false;
		let ignore = false;

		const onMessageReceived = (payload: any) => {
			let payloadData: any = JSON.parse(payload.body);
			if (timeoutRef) {
				globalThis.clearTimeout(timeoutRef.current);
			}
			switch (payloadData.action) {
				case "start_session":
					WebsocketClientUSerStatus.updateSessionStatus(
						payloadData.metadata.session_status
					);
					if (
						payloadData &&
						payloadData.metadata.session_status === "SESSION_STARTED"
					) {
						if (!isMsgReceived) {
							let participantDetailList = JSON.parse(
								localStorage.getItem("participantVolumeDetails") ?? ""
							);

							const initialVolumeMsg = {
								action: "audio_control",
								timestamp: new Date().toISOString(),
								session_id: payloadData.metadata.session_id,
								created_by: user.id,
								created_for: user.email,
								audio: {
									updated_for: "master",
									master: {
										audio_status: "unmute",
										audio_level: 50,
									},
									channel_1: {
										audio_status: "unmute",
										audio_level: 50,
									},
									channel_2: {
										audio_status: "unmute",
										audio_level: 50,
									},
									mic: {
										audio_status: "mute",
										audio_level: 0,
									},
								},
							};
							WebsocketClientUSerStatus.handleAudioControl(initialVolumeMsg);
							participantDetailList = normalizeAudioControls(payloadData);
							// console.log(`onAudioControlDetailsOfAllUsersRecieved and update from videoSession for ${user.id}`, { payloadData, participantDetailList, time: new Date().toISOString() });
							localStorage.setItem( "participantVolumeDetails", JSON.stringify(participantDetailList));
							isMsgReceived = true;
						}
					} else if (
						payloadData &&
						payloadData.metadata.session_status === "SESSION_HOLD"
					) {
						if (payloadData.metadata.created_by === user.email) {
							setIsSessionHold(true);
						}
					} else if (
						payloadData &&
						payloadData.metadata.session_status === "SESSION_STOPPED"
					) {
						if (payloadData.metadata.created_by === user.email) {
							// WebsocketClientUSerStatus.addLog("Session stop in videosession");
							setShowAlert(true);
							setAlertMsg("Device not reachable! Please try again..");
							setAlertStatus(false);
							leaveSession();
						}
					}
					break;
				case "Error":
					// alert("Error !!");
					break;
				default:
					setShowAlert(true);
					setAlertMsg("Device not reachable! Please try again..");
					setAlertStatus(false);
			}
		};

		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/sessions/${locationState?.session.sessionUuid}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((response) => {
				if (response.status >= 400) {
					throw new Error();
				} else {
					if (!ignore) {
						return response.json();
					}
				}
			})
			.then((data) => {
				if (data) {
					ignore = true;
					setStartTime(data.startTime);
					localStorage.setItem("twilioSessionId", data.twilioSessionId);
					WebsocketClientUSerStatus.startSession(data, user);
					timeoutRef.current = setTimeout(() => {
						// setLoading(false);
						handleStartSessionResponse(false);
					}, 15000);
					request();
				}
			});

		WebsocketClientUSerStatus.on("subcribed", onMessageReceived);

		return () => {
			ignore = true;
			WebsocketClientUSerStatus.off("subcribed", onMessageReceived);
		};
	}, []);

	const handleStartSessionResponse = (status: Boolean) => {
		setShowAlert(true);
		setAlertMsg("Device not reachable! Please try again..");
		setAlertStatus(false);
	};

	const handleWindowClose = async (event: any) => {
		// let response = leaveSession();
		event.preventDefault();
		event.stopPropagation();
		event.returnValue = "";
		return false;
	};

	const leaveSession = () => {
		let message = {
			//action: "participant_stop_session",
			action: "session_stop",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			// created_for: forParticipant,
			created_by: user.id,
			unsubscribe:
				dailyRoomObj && Object.keys(dailyRoomObj.participants()).length === 0
					? true
					: false,
		};

		// WebsocketMetricsClient.disconnectWebsocketConnection();

		stopSessionRecording(twilioSessionId, user);
		WebsocketClientUSerStatus.handleLeaveSession(message);
		WebsocketClientUSerStatus.onLeaveSession(twilioSessionId, user);
		// room?.disconnect();
		clearLocalstorage();
	};

	const handleDialogClose = () => {
		setIsSessionHold(false);
		leaveSession();
		navigate("/jamsession");
	};

	useEffect(() => {
		window.addEventListener("beforeunload", handleWindowClose);
		// window.addEventListener("unload", handleUnload);
		return () => {
			window.removeEventListener("beforeunload", handleWindowClose);
			// window.removeEventListener("unload", handleUnload);
		};
	}, []);

	useEffect(() => {
		if (isFullscreenMode) {
			setContainerheight(`100vh`);
		} else {
			setContainerheight(`calc(100vh - 72px)`);
		}
		// console.log('full screen mode changed', { isFullscreenMode, containerheight })
	}, [isFullscreenMode, containerheight]);

	const handleAlertClose = () => {
		setShowAlert(false);
		leaveSession();
		navigate("/jamsession");
	};

	const handleClose = () => {
		setDeviceError(false);
		if (Number(errorCode) === 1) {
			if (deviceId === user?.deviceId) {
				leaveSession();
				navigate("/jamsession");
			}
		} else if (errorCode === "501" || errorCode === "401") {
			handleAlertClose();
		} else {
			return;
		}
	};

	return (
		<>
			<Container
				className=" mainPanelSession"
				id="fullscreen-container"
				style={{
					height: isFullscreenMode
						? containerheight
						: isTabHidden
							? `${window.innerHeight - 5}px`
							: containerheight,
					backgroundColor: "#000000",
					overflow: "hidden",
				}}
			>
				{showDailyCall && <DailyRoom
					setCallObjectInVideoSession={setCallObject}
					setDailyRoomState={setDailyRoomState}
					dailyUrl={dailySession?.dailyRoomUrl && dailyToken ? `${dailySession?.dailyRoomUrl}?t=${dailyToken}` : ''}
				/>}
				{isSessionHold && (
					<SessionHoldDialog
						isSessionHold={isSessionHold}
						back={handleDialogClose}
					/>
				)}

				<CustomAlert alertMsg={alertMsg} alertStatus={alertStatus} handleAlertClose={handleAlertClose} showAlert={showAlert} />
			</Container>
			<DeviceError deviceError={deviceError} errorCode={errorCode} errorMessage={errorMessage} handleClose={handleClose} />
		</>
	);
}
const VAppMemoised = memo(VApp);

function VideoSession() {
	const [callObject, setCallObject] = useState<DailyCall | null>(null);
	console.log('VideoSession....');

	const setCallObjectData = useCallback((dailyCallObject: DailyCall) => {
		setCallObject(dailyCallObject)
	}, []);

	return (
		<RecoilRoot>
			{callObject !== null ?
				<DailyProvider callObject={callObject}>
					<VAppMemoised setCallObject={setCallObjectData} />
				</DailyProvider>
				:
				<VAppMemoised setCallObject={setCallObjectData} />
			}
		</RecoilRoot>
	);
}
export default memo(VideoSession);