import { useEffect, useState } from "react";
import {
	Card,
	CardActions,
	CardContent,
	Button,
	Typography,
	Grid,
} from "@mui/material";
import AddDeviceDialog from "../../dialogBox/AddDeviceDialog";
import Danger from "../../images/danger.svg";
import RightAarrow from "../../images/right arrow.svg";
import i18n from "../../l10n/strings.json";
import { useTheme } from "@mui/material/styles";
import { useUser } from "../../context/UserContext";

const AddDevice = (props: any) => {
	const user = useUser();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [subscriptionPlans, setSubscriptionPlans] = useState([]);
	const [loading, setLoading] = useState(true);
	const token = localStorage.getItem("sessionId");

	const handleSuccess = () => {
		setOpen(false);
		props.onSuccess();
	};
	useEffect(() => {
		getSubscriptionPlans();
	}, []);
	const getSubscriptionPlans = async () => {
		setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/master-subscription-plans`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})

			.then((data) => {
				if (data.length > 0) {
					// Parse features if they are JSON strings
					const plansWithParsedFeatures = data.map((plan: any) => {
						if (typeof plan.features === "string") {
							try {
								plan.features = JSON.parse(plan.features);
							} catch (error) {
								console.error(
									"Failed to parse features for plan:",
									plan.name,
									error
								);
								plan.features = [];
							}
						} else if (!Array.isArray(plan.features)) {
							plan.features = [];
						}
						return plan;
					});
					setSubscriptionPlans(plansWithParsedFeatures);
				}
			})
			.catch((err) => { })
			.finally(() => {
				setLoading(false);
			});
	};


	const checkOut = (plan: any) => {

		// if (loading) return; // Prevent API call if already loading
		// setLoading(true); // Reset loading state
		// fetch(`https://app.sealsubscriptions.com/shopify/merchant/api/quick-checkout-url`, {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/public/checkout`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				//"X-Seal-Token": sealToken,
				// "X-Seal-Token": "seal_token_04gf07v4d5nbhanxzwrfaicre65bl3yiiindmhxq"      //pick this from env as per environment
			},
			body: JSON.stringify({
				action: "create",
				items: [
					{
						variant_id: plan.shopifyVariantId,
						quantity: 1,
						//selling_plan: plan.shopifySellingPlanId
						inventory_item_id: plan.inventoryItemId, //fetch from master api
						product_id: plan.shopifyProductId,
					},
				],
				attributes: {
					checkout: {
						email: user.email,
						shipping_address: {
							first_name: user.firstName,
							last_name: user.lastName,
							address1: user.location ? user.location : "",
							phone: "",
							zip: "",
							city: "",
							province: "",
							country: "",
						},
					},
				},
			}),
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data.success) {
					// To Open in a new browsr window
					window.open(data.payload.quick_checkout_url, "_blank");
				}
			})
			.catch((err) => {
				// setActiveDevice(null);
			})
			.finally(() => {
				// setLoading(false);
			});
	};

	return (
		<>
			<Grid container={true} xs={12}>
				<Card
					sx={{
						background: "secondary",
						boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
						borderRadius: "12px",
						// height: "376px",
					}}
				>
					<CardContent>
						<Grid container={true} xs={12}>
							<Grid item={true} xs={1} sx={{ textAlign: "center" }}>
								<img src={Danger} alt="Preferences" />
							</Grid>

							<Grid item={true} xs={11}>
								<Typography variant="h3" sx={{color: "customColor.black"}}>
									{i18n.jamSession.addDevice}
								</Typography>
							</Grid>
						</Grid>

						<Grid item={true}>
							<Grid item={true} xs={12} sx={{ ml: 2 }}>
								<Typography
									sx={{
										lineHeight: "20px",
										padding: "20px 0px 0px 0px",
									}}
								>
									If you’ve already purchased a Lutefish Stream, click{" "}
									<Typography component="span" sx={{ color: "red" }}>
										[Add New Device]
									</Typography>{" "}
									to link it to your account.
								</Typography>

								<Typography
									sx={{
										lineHeight: "20px",
										padding: "20px 0px 0px 0px",
									}}
								>
									If this is a brand-new device, it will need to update before use. Just power it on once, then unplug and reconnect the USB-C cable to initiate the update process. If you haven’t purchased a device yet, click  <Typography	component="span"
										sx={{
											 color:"red"
										}}
									> [Buy Device]</Typography> to get started.
								</Typography>

								<Typography
									sx={{
										lineHeight: "20px",
										padding: "20px 0px 0px 0px",
									}}
								>
									<strong>Note:</strong> A linked device is required to upgrade your subscription plan.
								</Typography>
							</Grid>
						</Grid>

					</CardContent>
					<CardActions
						sx={{
							justifyContent: "flex-end",
							marginRight: "10px",
							marginTop: "25px",
						}}
					>
						<Button
							sx={{
								background: user.userAccountStatus === "SUSPEND" ? "grey" : theme.gradient.gradient1,
								height: "48px",
								color: "secondary",
								borderRadius: "24px",
								maxWidth: "247px"
							}}
							className="submit-button"
							size="large"
							fullWidth
							endIcon={<img src={RightAarrow} alt="Right Arrow" />}
							variant="contained"
							disabled={user.userAccountStatus === "SUSPEND"}
							onClick={() => (setOpen(true))}
						>
							<Typography>
								{i18n.jamSession.add}
							</Typography>
						</Button>
						{subscriptionPlans.map((plan: any, index) => {
							return (plan.type === "Electronics" &&
								plan.name?.toLowerCase().includes("lutefish")) ? (
								<Button
									className="submit-button"
									size="large"
									sx={{background: user.userAccountStatus === "SUSPEND" ? "grey" : theme.gradient.gradient1,
										height: "48px",
										color: "secondary",
										borderRadius: "24px",
										maxWidth: "247px",
										minWidth: "247px" }}
									endIcon={<img src={RightAarrow} alt="Right Arrow" />}
									variant="contained"
									onClick={() => {
										checkOut(plan);
									}}
								>
									<Typography>Buy Device</Typography>
								</Button>
							) : "";
						})
						}
					</CardActions>
				</Card>
			</Grid>
			<AddDeviceDialog
				open={open}
				updateAlertMsg={props.updateAlertMsg}
				onSuccess={handleSuccess}
				onClose={() => (setOpen(false))}
			/>
		</>
	);
};

export default AddDevice;
