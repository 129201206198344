import { useCallback, useState, useRef, useEffect  } from 'react';
import { useAppMessage, useLocalSessionId, useParticipantProperty } from '@daily-co/daily-react';
import CloseIcon from '../../../icons/CloseIcon';
import { DailyChatType } from '../Types/dailyTypes';
import { DailyDrawerWrapper, AddMessage, DrawerContent,ChatMessage,ChatMessageBody,ChatForm, ChatInput, ChatSubmitButton ,DailyDrawerCloseButton, DailyDrawerHeader, DailyDrawerTitle, Avatar, SenderBubble, SenderName, SenderMessageWrapper, ReceiverMessageWrapper, ReceiverBubble} from './ChatStyled'
import { useDailyUtils } from '../useDailyUtils';
import SendMessage from "../../../images/send.svg";
import Profile from "../../../images/profile-circle-auto.svg";
import { useDailyAppRoom } from '../useDailyGlobalRoom';
import { useFullscreenModeContext } from '../../../context/FullscreenModeContext';
import { getUserAvatar } from '../../MixerSlider/MixerSlider';
import { getUserInfo } from './helper';

export default function Chat({ toggleChat }: DailyChatType) {
  const [inputValue, setInputValue] = useState<string>('');
  const {getUserByEmail} = useDailyUtils();
  const {dailyChatMessages, setDailyChatMessages, isChatOpen, setHasNewChatMessage, setShowNewMessageNotification} = useDailyAppRoom();
  const { isFullscreenMode, isTabHidden } = useFullscreenModeContext();
  const localSessionId = useLocalSessionId();
  const username = useParticipantProperty(localSessionId, 'user_name');
  const messageContainerRef = useRef<HTMLUListElement>(null); // Ref for DrawerContent
  const inputRef = useRef<HTMLInputElement | null>(null);

  const sendAppMessage = useAppMessage({
    onAppMessage: useCallback(
      (ev:any) =>{
        // if msg  is empty or chat window is open, do not set the messages directly as it's being listen in useDailyAppRoom and update the messages.
        if(!ev.data.msg || isChatOpen){
          return;
        }
        setDailyChatMessages((existingMessages : any) => [
          ...existingMessages,
          {
            messageId: ev.data.messageId,
            msg: ev.data.msg,
            name: ev.data.name,
          },
        ])
      },
      [],
    ),
  });

  const sendMessage = useCallback(
    (message: string, messageId: string) => {
      /* Send the message to all participants in the chat - this does not include ourselves!
       * See https://docs.daily.co/reference/daily-js/events/participant-events#app-message
       */
      sendAppMessage(
        {
          messageId,
          msg: message,
          name: username || 'Guest',
        },
        '*',
      );

      /* Since we don't receive our own messages, we will set our message in the messages array.
       * This way _we_ can also see what we wrote.
       */
      setDailyChatMessages([
        ...dailyChatMessages,
        {
          messageId,
          msg: message,
          name: username || 'Guest',
        },
      ]);
    },
    [dailyChatMessages, sendAppMessage, username],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const messageId = crypto.randomUUID();
    if (!inputValue.trim()) return; // don't allow people to submit empty strings
    sendMessage(inputValue, messageId);
    setInputValue('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const getMessageView = (message: any) => {
    // console.log({message, username}, 'message');
    if(message?.name !== username){
      return(
      <SenderMessageWrapper>
        {getUserAvatar(getUserInfo(message?.name), true)}
        <SenderBubble>
          <SenderName>{getUserByEmail(message?.name, true)}</SenderName>
          <ChatMessageBody>{message?.msg}</ChatMessageBody>
        </SenderBubble>
      </SenderMessageWrapper>)
    }
    return(
      <ReceiverMessageWrapper>
        <ReceiverBubble>
          {/* <SenderName>{getUserByEmail(message?.name, true)}</SenderName> */}
          <ChatMessageBody>{message?.msg}</ChatMessageBody>
        </ReceiverBubble>
        {/* <Avatar src="https://via.placeholder.com/30" alt="Avatar" style={{marginLeft: '10px', marginRight: 0}}/> */}
      </ReceiverMessageWrapper>
    )
  }

  useEffect(()=>{
    if (inputRef.current) {
      inputRef.current.focus();
    }
  },[])

  // Auto-scroll to the latest message
  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [dailyChatMessages]); // Trigger scrolling when messages change

  return (
    <DailyDrawerWrapper isFullscreenMode={isFullscreenMode || isTabHidden}>
      <DailyDrawerHeader>
        <DailyDrawerTitle>Chat</DailyDrawerTitle>
        <DailyDrawerCloseButton 
          onClick={() => {
            toggleChat(false);
            setHasNewChatMessage(false);
            setShowNewMessageNotification(false);
          }}
          type="button"
        >
          <CloseIcon />
        </DailyDrawerCloseButton>
      </DailyDrawerHeader>
      <DrawerContent as="ul" ref={messageContainerRef}>
        {dailyChatMessages.map((message, index) => (
          <ChatMessage key={`message-${index}`}>
            {getMessageView(message)}
          </ChatMessage>
        ))}
      </DrawerContent>
      <AddMessage>
        <ChatForm onSubmit={handleSubmit}>
          <ChatInput
          ref={inputRef}
            type="text"
            placeholder="Type your message here.."
            value={inputValue}
            onChange={handleChange}
            onFocus={()=> {
              setHasNewChatMessage(false);
              setShowNewMessageNotification(false);
            }}
          />
          <ChatSubmitButton type="submit">
            <img src={SendMessage} alt="Send Message" />
          </ChatSubmitButton>
        </ChatForm>
      </AddMessage>
    </DailyDrawerWrapper>
  );
}
