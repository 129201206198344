import {
	Typography,
	Select,
	MenuItem,
	ListItemButton,
	ListItemText,
	Divider,
	IconButton,
	Popper,
	Badge,
	Menu,
	ListItemIcon,
	Snackbar,
	Tooltip,
	Button
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "../../../images/none.svg";
import { Constants } from "../../../services/constants";
import EndCallButton from "../../Buttons/EndCallButton/EndCallButton";
import ToggleChatButton from "../../Buttons/ToggleChatButton/ToggleChatButton";
import ToggleVideoButton from "../../Buttons/ToggleVideoButton/ToggleVideoButton";
import ToggleScreenShareButton from "../../Buttons/ToogleScreenShareButton/ToggleScreenShareButton";
import AudioControl from "../../MenuBar/AudioControl";
import i18n from "../../../l10n/strings.json";
import PerformanceHall from "../../../images/techno.svg";
import RehearsalStudio from "../../../images/hard-rock.svg";
import None from "../../../images/none.svg";
import JazzClub from "../../../images/vocal.svg";
import Theatre from "../../../images/bass.svg";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import People from "../../../images/people.svg";
import VideoOn from "../../../images/video.svg";
import StartFullscreen from "../../../images/maximize.svg";
import StopFullscreen from "../../../images/stop-fullscreen.svg";
import Smileys from "../../../images/smileys.svg";
import SessionMenuIcon from "../../../images/menu-dots.svg";
import EndLiveStreaming from "../../../images/stop-broadcasting.svg";
import ChangeLayout from "../../../images/change-layout.svg";
import HideTab from "../../../images/hide-tab.svg";
import ShowTab from "../../../images/show-tab.svg";
import SessionInfo from "../../../images/session-info.svg";
import LiveStreaming from "../../../images/live-streaming.svg";
import ActiveRecording from "../../../images/active-record.svg";
import Recording from "../../../images/record.svg";
import MusicOn from "../../../images/MusicOn.svg";
import ActiveMixer from "../../../images/active-mixer.svg";
import MixerIcon from "../../../images/candle.svg";
import { ReactComponent as VideoRecordingIcon } from "../../../images/recording-video.svg";
import { ReactComponent as AudioRecordingIcon } from "../../../images/music-symbol.svg";
import { ReactComponent as LiveStreamingIcon } from "../../../images/live-streaming-on.svg";
import { useUser } from "../../../context/UserContext";
import WebsocketClientUSerStatus from "../../../services/WebsocketClientUSerStatus";
import { useDaily, useParticipantIds, useRoom } from "@daily-co/daily-react";
import { DailyActionsWrapper, TrayContainer, TrayWrapper } from "./TrayStyled";
import { useFullscreenModeContext } from "../../../context/FullscreenModeContext";
// import { useDailyUtils } from "../useDailyUtils";
import { useDailyAppRoom } from "../useDailyGlobalRoom";
import { SessionInterface } from "../../../types/types";
import useLocalRecording from "../useLocalrecording";
import ActionDialog from "../../reusable-components/ActionDialog";
import CustomChipComponent from "../../reusable-components/CustomChipComponent";
import StartLiveStream from "../LiveStreamingDialog/StartLiveStreamDialog";
import EndLiveStream from "../LiveStreamingDialog/EndLiveStreamDialog";
import SessionInformation from "../LiveStreamingDialog/SessionInformationDialog";
import { useBroadcastingStatusContext } from "../../../context/BroadcastingStatusContext";
import { syncAudioControls } from "../../Mixer/utils";

type DailyTray = {
	chatToggle: Function;
	participantsToggle: Function;
	leaveCall: () => void;
	isMixerOpen: boolean;
	mixerToggle: Function
};


type SnackbarProps = {
	isOpen: boolean;
	handleClose: () => void;
	snackbarMessage: string;
	iconOnClick: () => void;
}

const renderSnackbar: React.FC<SnackbarProps> = ({
	isOpen,
	handleClose,
	snackbarMessage,
	iconOnClick,
}) => (
	<Snackbar
		sx={{
			"&.MuiSnackbar-anchorOriginTopRight": {
				top: "140px",
				"& .MuiSnackbarContent-root": {
					background: "#000",
					border: "1px solid #5C5C5C",
					borderRadius: "6px",
					color: "#C5C5C5",
				},
			},
		}}
		open={isOpen}
		onClose={handleClose}
		anchorOrigin={{ vertical: "top", horizontal: "right" }}
		message={snackbarMessage}
		autoHideDuration={3000}
		action={
			<IconButton onClick={iconOnClick}>
				<img src={CloseIcon} alt="close" />
			</IconButton>
		}
	/>
);

const audioEffects = [
	{ label: "None", value: "none", icon: None },
	{
		label: "Rehearsal Studio",
		value: "rehearsal",
		icon: RehearsalStudio,
	},
	{ label: "Jazz Club", value: "jazz", icon: JazzClub },
	{ label: "Theatre ", value: "reverb_2", icon: Theatre },
	{
		label: "Performance Hall",
		value: "performance_space",
		icon: PerformanceHall,
	},
];

const SessionMenu = [
	{
		label: "Live Streaming",
		id: "liveStreaming",
		icon: EndLiveStreaming,
	},
	{
		label: "Change Layout",
		id: "changeLayout",
		icon: ChangeLayout,
	},
	{
		label: "Hide tab bar",
		id: "hideTab",
		icon: HideTab,
	},
	{
		label: "Session info",
		id: "sessionInfo",
		icon: SessionInfo,
	},
];

const RecordingMenu = [
	{
		label: 'Record Video + Audio',
		id: 'audioplusvideo',
		icon: VideoOn,
	},
	{
		label: 'Record only audio',
		id: 'onlyaudio',
		icon: MusicOn,
	},
];

function DailyMenuBar({ chatToggle, participantsToggle, leaveCall, isMixerOpen, mixerToggle }: DailyTray) {
	const user = useUser();
	const room = useRoom();

	const token = localStorage.getItem("sessionId");
	let _audioEffect = localStorage.getItem("sessionAudioEffect");
	const twilioSessionId = localStorage.getItem("twilioSessionId") ?? "";

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [anchorElRecording, setAnchorElRecording] = useState<null | HTMLElement>(null);
	const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
	const [broadcastDialogOpen, setBroadcastDialogOpen] = useState(false);
	const [open, setOpen] = useState(false);
	const [endSnackBarOpen, setEndSnackBarOpen] = useState(false);
	const [newChatMessage, setNewChatMessage] = useState(false);
	const [recordingTimer, setRecordingTimer] = useState<any>("");
	const [userSession, setUserSession] = useState(() => {
		const _userSession = localStorage.getItem("userSession");
		return _userSession && typeof _userSession === "string"
			? JSON.parse(_userSession)
			: null;
	});
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [openRecordingEndDialog, setOpenRecordingEndDialog] = useState<boolean>(false);
	const [enableShowStopRecording, setEnableShowStopRecording] = useState(true);
	const [recordingDisable, setIsRecordingDisable] = useState(false);
	const recordingIntervalRef = useRef<any | undefined>(undefined);
	const [isLiveStreamingEnded, setIsLiveStreamingEnded] = useState(false);
	const [disabledRecordButton, setDisabledRecordButton] = useState(false);
	const [sessionInformationDialogBoxOpen, setSessionInformationDialogBoxOpen] = useState(false);
	const [streamStartedBy, setStreamStartedBy] = useState(null);
	const [selectedRecordingOption, setSelectedRecordingOption] = useState<string>("");
	const [audioEffect, setAudioEffect] = useState(_audioEffect ?? audioEffects[0].value);
	const [openStartLiveStreamDialog, setOpenStartLiveStreamDialog] = useState(false);
	const [openEndLiveStreamDialog, setOpenEndLiveStreamDialog] = useState(false);

	// All Hooks other than state
	const { liveStreamingStartedBy, setLiveStreamingStartedBy, recordingDetails, setRecordingDetails, setIsLiveStreaming, isLiveStreaming } =
		useBroadcastingStatusContext();
	const { isParticipantsOpen, setParticipantsOpen, isChatOpen, setIsChatOpen } = useDailyAppRoom();
	const { isFullscreenMode, setIsFullscreenMode, isTabHidden, setIsTabHidden } = useFullscreenModeContext();
	const { setIsScreenSharing, toggleSendingAudioFromDevice } = useDailyAppRoom();
	const { startDailyRecording, stopDailyRecording, recordingInstanceId } = useLocalRecording();
	const callObject = useDaily();

	const deviceIds = callObject
  ? userSession.attendees
      .filter((attendee: any) =>
        Object.values(callObject?.participants() || {}).some(
          (participant) => participant?.user_name === attendee?.email
        )
      )
      .map((attendee: any) => attendee?.deviceId)
  : [];

	let _isStorageAvaiable: boolean =
		localStorage.getItem("storageUsage") !==
			localStorage.getItem("storageCapacity")
			? true
			: false;


	const handleCloseStartLiveStreamDialog = () => {
		setOpenStartLiveStreamDialog(false);
	};
	const handleCloseEndLiveStreamDialog = () => {
		setOpenEndLiveStreamDialog(false);
	};


	const handleAudioEffect = (event: any) => {
		let message = {
			action: "audio_effect",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			audio_effect: event.target.value === "none" ? "" : event.target.value,
			deviceIds: deviceIds ?? []
		};
		WebsocketClientUSerStatus.handleAudioEffect(message);
	};

	useEffect(() => {
		fetchSessionDetails();
		WebsocketClientUSerStatus.on("recordingControl", onMessageReceivedAudioRecording);
		WebsocketClientUSerStatus.on("audioEffect", onMessageReceivedAudioEffect);
		WebsocketClientUSerStatus.on("liveStreaming", onMessageReceivedLiveStreaming);
		WebsocketClientUSerStatus.on("videoRecording", onMessageReceivedVideoRecording);
		WebsocketClientUSerStatus.on("updatedPrefferedChannelName", onUpdatedPreferredChannelNameMessage);

		setIsLiveStreaming(userSession?.dailyLiveStreamStatus);
		setStreamStartedBy(userSession?.dailyLiveStreamStartedBy);
		updateExistingRecordingDetails();

		return () => {
			WebsocketClientUSerStatus.off("recordingControl", onMessageReceivedAudioRecording);
			WebsocketClientUSerStatus.off(
				"audioEffect",
				onMessageReceivedAudioEffect
			);
			WebsocketClientUSerStatus.off("liveStreaming", onMessageReceivedLiveStreaming);
			WebsocketClientUSerStatus.off("videoRecording", onMessageReceivedVideoRecording);
			WebsocketClientUSerStatus.off(
				"updatedPrefferedChannelName",
				onUpdatedPreferredChannelNameMessage
			);
			globalThis.clearInterval(recordingIntervalRef.current);
		};
	}, []);

	useEffect(() => {
		if (!callObject) return;

		const handleDailyRecordingStarted = (event: any) => {
			let userName = ""

			// Get the ID of the participant who started the recording
			const startedById = event.startedBy;

			// Get all participants, including local
			const participants = callObject.participants();

			// Check if the startedById is the local participant
			if (participants.local && participants.local.user_id === startedById) {
				userName = participants.local.user_name;
			} else if (participants[startedById]) {
				const remoteParticipant = participants[startedById];
				userName = remoteParticipant.user_name || "";
			} else {
				// console.log("Could not find participant info for the user who started the recording.");
				return;
			}

			setRecordingDetails({
				recordingStarted: true,
				recordingStartedBy: userName,
				recordingType: "audioplusvideo"
			});
			sendRecordingStartedDetails(userName);
		};

		const handleDailyRecordingStopped = (event: any) => {
			// console.log('Recording stopped event:', event);
			setRecordingDetails({
				recordingStarted: false,
				recordingStartedBy: "",
				recordingType: "audioplusvideo"
			});
		};

		callObject.on('recording-started', handleDailyRecordingStarted);
		callObject.on('recording-stopped', handleDailyRecordingStopped);

		return () => {
			callObject.off('recording-started', handleDailyRecordingStarted);
			callObject.off('recording-stopped', handleDailyRecordingStopped);
		};
	}, [callObject]);

	const sendRecordingStartedDetails = async (recordingStartedByEmail: string) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/update-session/recording-started-by`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						twilioSessionId,
						recordingStartedBy: recordingStartedByEmail,
					}),
				}
			)
			if (response.ok) {
				console.log("Successfully sent data to backend.");
			} else {
				console.error("Failed to send data to backend.", response.status);
			}
		} catch (error) {
			console.error("Error sending data to backend:", error);
		}
	}

	const updateExistingRecordingDetails = () => {
		setRecordingDetails({
			...recordingDetails,
			recordingStarted: userSession?.recordingStatus,
			recordingStartedBy: userSession?.recordingStartedBy || userSession.audioRecordingStartedBy || "",
			recordingType: "onlyaudio"
			// recordingType: userSession?.recordingStartedBy ? "audioplusvideo" : "onlyaudio" // Do not remove this, it will be used in AV recording
		});

		if (userSession?.recordingStatus && userSession?.recordingStartTime) {
			if (!(recordingDetails?.recordingStartedBy === user?.id || recordingDetails?.recordingStartedBy === user?.email)) {
				setIsRecordingDisable(true);
			}
			const sTime =
				new Date(userSession?.recordingStartTime.toString()) ?? new Date();
			startRecordingTimer(sTime);
		}
	}

	// useEffect(() => {
	// 	if (!callObject) return;

	// 	const handleTrackStarted = (event: any) => {
	// 		console.log('Track started event:', event);
	// 		const { participant, track } = event;
	// 		console.log('Track started for participant:', participant.user_name, track.kind);

	// 		// Handle storage or processing of individual tracks
	// 	};

	// 	const handleTrackStopped = (event: any) => {
	// 		console.log('Track stopped event:', event);
	// 		const { participant, track } = event;
	// 		console.log('Track stopped for participant:', participant.user_name, track.kind);
	// 	};

	// 	callObject.on('track-started', handleTrackStarted);
	// 	callObject.on('track-stopped', handleTrackStopped);

	// 	return () => {
	// 		callObject.off('track-started', handleTrackStarted);
	// 		callObject.off('track-stopped', handleTrackStopped);
	// 	};
	// }, [callObject]);

	// useEffect(() => {
	// 	const handleFullscreenChange = () => {
	// 		if (!document.fullscreenElement) {
	// 			setIsFullscreenMode(false);
	// 		}
	// 	};

	// 	document.addEventListener('fullscreenchange', handleFullscreenChange);

	// 	return () => {
	// 		document.removeEventListener('fullscreenchange', handleFullscreenChange);
	// 	};
	// }, [setIsFullscreenMode]);



	// useEffect(() => {
	// 	if (
	// 		(recordingDetails?.recordingStarted) &&
	// 		userSession?.recordingStartTime
	// 	) {
	// 		if (!(recordingDetails?.recordingStartedBy === user?.id ||recordingDetails?.recordingStartedBy === user?.email)) {
	// 			setIsRecordingDisable(true);
	// 		}
	// 		const sTime =
	// 			new Date(userSession?.recordingStartTime.toString()) ?? new Date();
	// 		startRecordingTimer(sTime);
	// 	}
	// }, [isFullscreenMode]);

	const onMessageReceivedLiveStreaming = (response: any) => {
		console.log("Response.....", response);
		// debugger;
		if (response?.session_id !== userSession?.twilioSessionId) {
			return false;
		}

		if (response?.dailyLiveStreamStatus === "START") {
			setStreamStartedBy(response.created_by);
			setLiveStreamingStartedBy && setLiveStreamingStartedBy(response.created_by);
			if (response.created_by === user?.id) {
				setBroadcastDialogOpen(!broadcastDialogOpen);
			}
			localStorage.setItem("userSession", JSON.stringify({
				...userSession,
				dailyLiveStreamStatus: true,
				dailyLiveStreamStartTime: response.timestamp,
				dailyLiveStreamStartedBy: response.created_by

			}));
			setIsLiveStreaming(true);
			setIsLiveStreamingEnded(false);
			setSnackbarMessage("Live Streaming has started");
			setOpen(true);
			setEndSnackBarOpen(false);
		} else if (response?.dailyLiveStreamStatus === "STOP") {
			setLiveStreamingStartedBy && setLiveStreamingStartedBy(null);
			localStorage.setItem("userSession", JSON.stringify({
				...userSession,
				dailyLiveStreamStatus: false
			}));
			setIsLiveStreaming(false);
			setIsLiveStreamingEnded(true);
			setSnackbarMessage("Live Streaming has ended");
			setEndSnackBarOpen(true);
			setOpen(false);
		}
	};

	const onMessageReceivedVideoRecording = (response: any) => {
		// debugger
		if (response?.session_id !== userSession?.twilioSessionId) {
			return false;
		}
		if (response?.recordingStatus === "START") {
			const sTime =
				// new Date(
				// 	response.metadata.recording_details.rec_start_time.toString()
				// ) ??
				new Date();
			globalThis.clearInterval(recordingIntervalRef.current);
			startRecordingTimer(sTime);
			setSnackbarMessage("Audio + Video recording has started")
			setOpen(true);
			setEndSnackBarOpen(false);
			setRecordingDetails({
				...recordingDetails,
				recordingStartedBy: response.created_by,
				recordingType: "audioplusvideo"
			});
			// debugger;
			startDailyRecording();
			// startDailyRecording(response.session_id);
			if (response.created_by === user?.id) {
				setEnableShowStopRecording(true);
			} else {
				setEnableShowStopRecording(false);
			}
		} else if (response?.recordingStatus === "STOP") {
			setSnackbarMessage("Audio + Video recording has ended")
			setOpen(false);
			setEndSnackBarOpen(true);
			setRecordingDetails({
				...recordingDetails,
				recordingStartedBy: "",
				recordingType: "audioplusvideo"
			});
			if (response.created_by === user?.id) {
				stopDailyRecording(recordingInstanceId);
				// stopDailyRecording(response.session_id);
			}
		}
	}
	const onMessageReceivedAudioRecording = (response: any) => {
		// debugger;
		if (response.action === "audio_recording") {
			debugger;
			console.log("check for storage capacity")
			if (response.metadata.recording_details.recording_status === "STARTED") {
				const isInitiator = response.metadata.created_by === user.email;
				setRecordingDetails({
					...recordingDetails,
					recordingStarted: true,
					recordingStartedBy: response.metadata.created_by,
					recordingType: "onlyaudio"
				});
				sendRecordingStartedDetails(response.metadata.created_by);
				setSnackbarMessage("Audio recording has started")
				setOpen(true);
				setEndSnackBarOpen(false);
				const sTime =
					// new Date(
					// 	response.metadata.recording_details.rec_start_time.toString()
					// ) ??
					new Date();
				globalThis.clearInterval(recordingIntervalRef.current);
				startRecordingTimer(sTime);
				fetchSessionDetails();
				// Set disable based on whether the current user started the recording or not
				if (!isInitiator) {
					setIsRecordingDisable(true); // Disable controls for users who did not start the recording
				}
			}
			if (
				response.metadata.recording_details.recording_status === "STOPPED" ||
				response.metadata.recording_details.recording_status === "FAILED"
			) {
				debugger;
				setRecordingDetails({
					...recordingDetails,
					recordingStarted: false,
					recordingStartedBy: "",
					recordingType: "onlyaudio"
				});
				if (userSession.recordingStatus) {
					userSession.recordingStatus = false;
					userSession.audioRecordingStartedBy = "";
					localStorage.setItem("userSession", JSON.stringify(userSession));
				}
				sendRecordingStartedDetails("");
				setSnackbarMessage("Audio recording has ended")
				setOpen(false);
				setEndSnackBarOpen(true);
				globalThis.clearInterval(recordingIntervalRef.current);
				setRecordingTimer('');
				fetchSessionDetails();
				// Set disable based on whether the current user stopped the recording or not
				if (response.metadata.created_by !== user.email) {
					setIsRecordingDisable(false); // Enable controls if the recording was stopped by another user
				}
			}
		}
	};

	const onMessageReceivedAudioEffect = (responseMsg: any) => {
		if (
			responseMsg.action === "audio_effect" &&
			responseMsg.metadata.audio_effect_status === "SUCCESS"
		) {
			setAudioEffect(
				responseMsg.metadata.audio_effect === ""
					? "none"
					: responseMsg.metadata.audio_effect
			);
			localStorage.setItem(
				"sessionAudioEffect",
				responseMsg.metadata.audio_effect === ""
					? "none"
					: responseMsg.metadata.audio_effect
			);
		}
	};

	const toggleEmojis = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		if (anchorEl === null) {
			setParticipantsOpen(false);
			setIsChatOpen(false);
			// chatToggle && chatToggle(false);
			// participantsToggle && participantsToggle(false);
			mixerToggle && mixerToggle(false)
		}
	};

	const handleEmoji = (emojiObj: any) => {
		let message = {
			action: "emoji",
			identity: user.email,
			key: emojiObj.key,
			// twilioSessionId: twilioSessionId,
		};
		// WebsocketClientUSerStatus.handleEmojiChange(message);
		window.dispatchEvent(
			new CustomEvent("reaction_added", { detail: { emoji: message.key } })
		);
		setAnchorEl(null);
	};

	const togglePeople = () => {
		setParticipantsOpen(!isParticipantsOpen);
		setAnchorEl(null);
		participantsToggle && participantsToggle(!isParticipantsOpen);
		if (isChatOpen) {
			setIsChatOpen(false);
			chatToggle && chatToggle(false);
		}
		if (isMixerOpen) {
			mixerToggle && mixerToggle(false)
		}
	};

	const toggleChat = () => {
		setIsChatOpen(!isChatOpen);
		setAnchorEl(null);
		chatToggle && chatToggle(!isChatOpen);
		if (newChatMessage) {
			setNewChatMessage(false);
		}
		if (isParticipantsOpen) {
			setParticipantsOpen(false);
			participantsToggle && participantsToggle(false);
		}
		if (isMixerOpen) {
			mixerToggle && mixerToggle(false)
		}
	};

	const toggleFullscreenMode = useCallback(() => {
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
			setIsFullscreenMode(true);
			setIsTabHidden(false);
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
			setIsFullscreenMode(false);
		}
	}, [setIsFullscreenMode]);

	const handleOpenSessionMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElMenu(event.currentTarget);
	};

	const startLiveStream = () => {
		if (userSession?.createdBy === user?.id) {
			let requestMsg = {
				action: "live_stream_status",
				timestamp: new Date(),
				session_id: twilioSessionId,
				created_by: user.id,
				dailyLiveStreamStatus: "START",
			};
			debugger;
			WebsocketClientUSerStatus.handleLiveStream(requestMsg);
			toggleSendingAudioFromDevice({ userId: user.id, dailySessionId: twilioSessionId, cmd: "START" });
		}
	}
	const endLiveStream = () => {
		if (userSession?.createdBy === user?.id) {
			let requestMsg = {
				action: "live_stream_status",
				timestamp: new Date(),
				session_id: twilioSessionId,
				created_by: user.id,
				dailyLiveStreamStatus: "STOP",
			};
			WebsocketClientUSerStatus.handleLiveStream(requestMsg);
			toggleSendingAudioFromDevice({ userId: user.id, dailySessionId: twilioSessionId, cmd: "STOP" });
		}
	}

	const handleCloseSessionMenu = (itemId: string) => {
		setAnchorElMenu(null);
		if (itemId === "hideTab") {
			setIsTabHidden(!isTabHidden);
		} else if (itemId === "sessionInfo") {
			setSessionInformationDialogBoxOpen(!sessionInformationDialogBoxOpen);
		} else if (itemId === "liveStreaming") {
			isLiveStreaming ? setOpenEndLiveStreamDialog(true) : setOpenStartLiveStreamDialog(true);
		}
	};

	const toggleAudioRecording = () => {
		const recordingStatus = !recordingDetails?.recordingStarted
			? Constants.RecordingCmd.START
			: Constants.RecordingCmd.STOP;
		if (_isStorageAvaiable) {
			console.log("storage avaiable");
		}
		//
		setDisabledRecordButton(true);
			setTimeout(() => {
			// After 5 seconds, set the variable to false
			setDisabledRecordButton(false);
		}, 5000);
		let message = {
			action: "audio_recording",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			recordingStatus: recordingStatus
		};
		debugger;
		// reseting the recording details for audio recording.
		sendRecordingStartedDetails('');
		// debugger;
		WebsocketClientUSerStatus.handleRecordingControl(message);
	};

	const toggleVideoRecording = () => {
		const recordingStatus = !recordingDetails?.recordingStarted
			? Constants.RecordingCmd.START
			: Constants.RecordingCmd.STOP;
		let message = {
			action: "video_recording",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			recordingStatus: recordingStatus
		};
		WebsocketClientUSerStatus.handleRecordingControl(message);
		toggleSendingAudioFromDevice({ userId: user.id, dailySessionId: twilioSessionId, cmd: recordingStatus });
	}

	const toggleMixer = () => {
		isMixerOpen && syncAudioControls(twilioSessionId)
		mixerToggle && mixerToggle(!isMixerOpen);
		setAnchorEl(null);
		if (isChatOpen) {
			setIsChatOpen(false);
			chatToggle && chatToggle(false);
		}
		if (isParticipantsOpen) {
			setParticipantsOpen(false);
			participantsToggle && participantsToggle(false);
		}
		// setIsChatWindowOpen(false);
		// setIsPeopleWindowOpen(false);
		// setAnchorEl(null);

	};

	const getTimePartsFromDuration = (duration: number) => {
		const ms = duration % 1000;
		const s = (duration - ms) / 1000;
		const seconds = s % 60;
		const m = (s - seconds) / 60;
		const minutes = m % 60;
		const hours = (m - minutes) / 60;
		return {
			hours: hours,
			minutes: minutes,
			seconds: seconds,
		};
	};

	const getTime = (timeDiff: number) => {
		const { hours, minutes, seconds } = getTimePartsFromDuration(timeDiff);
		return [hours, minutes, seconds]
			.map((digit) => digit.toString().padStart(2, "0"))
			.join(":");
	};

	const handleRecordingIconClick = (event: any) => {
		/* 
			Commenting dual recording code out as we are not using the recording menu.
			We will toggle recording from the recording icon.
			This code will  be used when we will use the recording menu. Do Not Delete the commented code.
		**/

		// if (!recordingDetails?.recordingStarted) {
		// 	setAnchorElRecording(event.currentTarget);
		// } else {
		// 	setAnchorElRecording(null);
		// }
		// if ((recordingDetails?.recordingStarted) && (selectedRecordingOption === "onlyaudio" ||
		// 	selectedRecordingOption === "audioplusvideo"
		// )) {
		// 	handleCloseRecording();
		// 	setOpenRecordingEndDialog(true);
		// }

		if (!recordingDetails?.recordingStarted) {
			toggleAudioRecording();
		} else if (recordingDetails?.recordingStarted) {
			if ((recordingDetails?.recordingType === "onlyaudio" || selectedRecordingOption === "onlyaudio")) {
				toggleAudioRecording();
			}
		}
	};

	const handleCloseRecording = () => {
		setAnchorElRecording(null);
	};

	const handleRecordingMenuItemClick = (menuId: any) => {
		if (menuId === 'onlyaudio') {
			toggleAudioRecording();
		} else if (menuId === 'audioplusvideo') {
			toggleVideoRecording();
		}
		handleCloseRecording();
		setSelectedRecordingOption(menuId);
	};

	const onUpdatedPreferredChannelNameMessage = (payload: any) => {
		const updatedUserSessions = { ...userSession };
		const attendee = updatedUserSessions?.attendees?.find(
			(attendee: any) =>
				attendee?.deviceId?.toUpperCase() ===
				payload.metadata.created_for?.toUpperCase()
		);

		if (attendee) {
			attendee.preferredChannelNames = payload.preferredChannelNames;
			localStorage.setItem("userSession", JSON.stringify(updatedUserSessions));
			setUserSession(updatedUserSessions);
		}
	};

	const startRecordingTimer = (sTime: any) => {
		// if (recordingIntervalRef.current === undefined) {
		recordingIntervalRef.current = setInterval(() => {
			var timeDiff = Date.now() - sTime.getTime();
			setRecordingTimer(timeDiff);
		}, 1000);
		// }
	};

	const fetchSessionDetails = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/session-details/${userSession?.sessionUuid}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status >= 400) {
				} else {
					return res.json();
				}
			})
			.then((session: SessionInterface) => {
				localStorage.setItem("userSession", JSON.stringify(session));
			});
	};

	const handleCloseEndRecordingDialog = () => {
		setOpenRecordingEndDialog(false);
	};

	const handleEndRecording = () => {
		if (recordingDetails?.recordingType === "onlyaudio" || selectedRecordingOption === "onlyaudio") {
			// debugger;
			toggleAudioRecording();
			handleCloseEndRecordingDialog();
		}
		if (recordingDetails?.recordingType === "audioplusvideo" || selectedRecordingOption === "audioplusvideo") {
			// debugger;
			toggleVideoRecording();
			handleCloseEndRecordingDialog();
		}
	}

	function formatRecordingTimer(recordingTimer: number): string {
		// Convert time difference to hours and minutes
		const totalMinutes = Math.floor(recordingTimer / (1000 * 60));
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;

		// Return formatted string based on totalMinutes
		if (totalMinutes >= 60) {
			return `${hours}:${minutes.toString().padStart(2, '0')} hrs`;
		} else {
			return `${totalMinutes} min`;
		}
	}


	const closeSessionInformationDialogBox = () => {
		setSessionInformationDialogBoxOpen(false);
	};


	const handeToggleHideTab = () => {
		if (isTabHidden) {
			setIsTabHidden(false);
		} else {
			setIsTabHidden(true);
		}
	};


	const handdleSnackbarClose = () => {
		setEndSnackBarOpen(false);
		setIsLiveStreamingEnded(false);
	}

	const leaveSession = () => {
		// debugger;
		if (isChatOpen) {
			setIsChatOpen(false);
			chatToggle && chatToggle(false);
		}
		if (isMixerOpen) {
			mixerToggle && mixerToggle(false)
		}
		if (isParticipantsOpen) {
			setParticipantsOpen(false);
			participantsToggle && participantsToggle(false);
		}
		leaveCall();
	}

	const isRecordingIconButtonDisabled = () => {
		if (user.storageUsage >= user.storageCapacity) return true;
		if (recordingDisable) return true;
		if (disabledRecordButton) return true;
		if (!enableShowStopRecording) return true;
	};

	const JamSessionMenubar = () => {
		return (
			<TrayWrapper>
				<TrayContainer>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							paddingLeft: "0p",
							width: "50%",
						}}
					>
						<Box sx={{ width: "80px !important" }}>
							<Typography
								sx={{
									fontSize: "1rem",
									marginRight: "5px !important",
									color: "#C5C5C5",
								}}
							>
								{recordingDetails?.recordingStarted && recordingTimer
									? getTime(recordingTimer)
									: ""}
							</Typography>
						</Box>
						{/* <Divider
							className="jamsession-divider"
							sx={{ margin: "0 2% !important" }}
							orientation="vertical"
						/> */}

						{isLiveStreaming && (
							<Box sx={{ mr: "5px" }}>
								<CustomChipComponent
									label="Live"
									icon={<LiveStreamingIcon />}
									backgroundColor="#03B547"
									borderColor="none"
									width="85px"
									height="30px"
									borderRadius="12px"
									iconSpacing="0px"
									fontSize="12px"
									fontWeight="500"
									labelColor="#fff"
								/>
							</Box>
						)
						}

						{recordingDetails?.recordingStarted && (
							<CustomChipComponent
								label="Recording"
								icon={recordingDetails?.recordingType === "audioplusvideo" ? <VideoRecordingIcon /> : <AudioRecordingIcon />}
								backgroundColor="#FF0000"
								borderColor="none"
								width="120px"
								height="30px"
								borderRadius="12px"
								iconSpacing="0px"
								fontSize="12px"
								fontWeight="500"
								labelColor="#fff"
							/>
						)}

						<Box
							sx={{ margin: "0px 10px !important" }}
							className="sessionTitle"
						>
							<Typography
								sx={{
									color: recordingDetails?.recordingStarted ? "#FF7070" : "#C5C5C5",
									fontSize: "1rem",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap"
								}}
							>
								{((userSession?.sessionName ?? room?.name) || "").length > 20
									? `${(userSession?.sessionName ?? room?.name).slice(0, 20)}...`
									: userSession?.sessionName ?? room?.name}
							</Typography>
						</Box>
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							width: "50%",
							justifyContent: "flex-end",
						}}
					>
						<Box sx={{ pr: "10px !important", width: "55px" }}>
							<Typography
								sx={{ color: "#C5C5C5", fontSize: "14px", fontWeight: "500" }}
								className="sessionTitle"
							>
								{i18n.jamSession.effects}
							</Typography>
						</Box>
						<Box sx={{ pr: "15px !important" }}>
							<Select
								id="demo-simple-select"
								value={audioEffect}
								MenuProps={{
									PaperProps: {
										style: {
											backgroundColor: "black",
											color: "#cfd9df",
											border: "1px solid #5c5c5c",
											boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
											borderRadius: "12px",
										},
									},
								}}
								sx={{
									border: "1px solid #5C5C5C",
									borderRadius: "6px",
									color: "#CFD9DF",
								}}
								onChange={handleAudioEffect}
								className="sessionSelect"
							>
								{audioEffects.map((effect: any) => (
									<MenuItem
										sx={{ p: 0 }}
										value={effect.value}
										key={effect.value}
									>
										<ListItemButton sx={{ padding: "0 16px" }}>
											<ListItemText
												primary={effect.label}
												primaryTypographyProps={{
													style: { color: "#CFD9DF" },
												}}
											/>
										</ListItemButton>
									</MenuItem>
								))}
							</Select>
						</Box>
						<Divider
							className="jamsession-divider"
							sx={{ margin: "0 2% !important" }}
							orientation="vertical"
						/>
						<DailyActionsWrapper>
							<IconButton sx={{ p: 0 }} className="sessionIconBtn">
								<ToggleScreenShareButton
									disabled={false}
									setIsScreenSharing={setIsScreenSharing}
								/>
							</IconButton>
							<div className={Boolean(anchorEl) ? "active-menu" : ""}>
								<Tooltip title={i18n.jamSession.emojiTooltip}>
									<IconButton
										sx={{ p: 0 }}
										className="sessionIconBtn"
										onClick={toggleEmojis}
									>
										<img src={Smileys} alt="Smileys" />
									</IconButton>
								</Tooltip>
							</div>
							<div className={isParticipantsOpen ? "active-menu" : ""}>
								<Tooltip
									title={
										isParticipantsOpen
											? i18n.jamSession.hidePeoplesTooltip
											: i18n.jamSession.peoplesTooltip
									}
								>
									<IconButton
										sx={{ p: 0 }}
										className="sessionIconBtn"
										onClick={togglePeople}
									>
										<img src={People} alt="People" />
									</IconButton>
								</Tooltip>
							</div>
							<div className={isChatOpen ? "active-menu" : ""}>
								<Tooltip
									title={
										isChatOpen
											? i18n.jamSession.hidechatTooltip
											: i18n.jamSession.chatTooltip
									}
								>
									<ToggleChatButton toggleChat={toggleChat} />
								</Tooltip>
							</div>
							<Tooltip
								title={
									isFullscreenMode
										? i18n.jamSession.stopFullscreenTooltip
										: i18n.jamSession.fullscreenTooltip
								}
							>
								<IconButton
									id="go-button"
									sx={{ p: 0 }}
									className="sessionIconBtn"
									onClick={toggleFullscreenMode}
								>
									<img
										src={isFullscreenMode ? StopFullscreen : StartFullscreen}
										alt="Start Fullscreen"
									/>
								</IconButton>
							</Tooltip>
							<div>
								<Tooltip title={i18n.jamSession.more}>
									<IconButton
										aria-controls={
											Boolean(anchorElMenu) ? "session-menu" : undefined
										}
										sx={{ p: 0 }}
										className="sessionIconBtn"
										onClick={handleOpenSessionMenu}
									>
										<Badge
											color="error"
											variant="dot"
											// invisible={
											// 	isTabHidden || ((isBroadcastingStarted && isLiveStreaming) || userSession.dailyLiveStreamStatus)
											// 		? false
											// 		: true
											// }
											invisible
										>
											<img src={SessionMenuIcon} alt="session-menu" />
										</Badge>
									</IconButton>
								</Tooltip>
								<Menu
									id="menu-appbar"
									anchorEl={anchorElMenu}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "left",
									}}
									keepMounted
									open={Boolean(anchorElMenu)}
									onClose={handleCloseSessionMenu}
									MenuListProps={{
										"aria-labelledby": "session-menu",
									}}
								>
									{SessionMenu.map((menu) => {
										if (
											menu.id === "liveStreaming" &&
											userSession?.sessionScope === "Private"
										) {
											return null;
										}
										return (
											<ListItemButton
												disabled={
													(menu.id === "hideTab" && isFullscreenMode) ||
													menu.id === "changeLayout" ||
													(menu.id === "liveStreaming" && (userSession?.createdBy !== user?.id ||
														!user?.subscriptionInformationDTOs?.some(
															(sub) =>
																sub.name === "Premium" &&
																(sub.status === "ACTIVE" || sub.status === "PAUSED")
														))
													)
												}
												key={menu.label}
												onClick={() => handleCloseSessionMenu(menu.id)}
											>
												{menu.id === "liveStreaming" ? (
													<ListItemIcon sx={{ minWidth: "35px" }}>
														<img
															src={
																(isLiveStreaming)
																	? EndLiveStreaming
																	: LiveStreaming
															}
															alt={menu.label}
														/>
													</ListItemIcon>
												) : menu.id === "hideTab" ? (
													<ListItemIcon sx={{ minWidth: "35px" }} onClick={handeToggleHideTab}>
														<img
															src={isTabHidden ? ShowTab : HideTab}
															alt={menu.label}
														/>
													</ListItemIcon>
												) : menu.id === "sessionInfo" ? (
													<ListItemIcon sx={{ minWidth: "35px" }}>
														<img src={SessionInfo} alt={menu.label} />
													</ListItemIcon>
												) : (
													<ListItemIcon sx={{ minWidth: "35px" }}>
														<img src={menu.icon} alt={menu.label} />
													</ListItemIcon>
												)}
												<ListItemText
													primary={
														menu.id === "liveStreaming"
															? isLiveStreaming
																? "End Streaming"
																: menu.label
															: menu.id === "hideTab" && isTabHidden
																? "Show tab bar"
																: menu.label
													}
												/>
											</ListItemButton>
										);
									})}
								</Menu>
							</div>
						</DailyActionsWrapper>

						<Divider
							className="jamsession-divider"
							sx={{ margin: "0 2% !important" }}
							orientation="vertical"
						/>
						<DailyActionsWrapper>
							<AudioControl />
							<IconButton sx={{ p: 0 }} className="sessionIconBtn">
								<ToggleVideoButton disabled={false} />
							</IconButton>
							{(userSession?.createdBy === user?.id) && (
								<Tooltip
									title={
										user.storageUsage >= user.storageCapacity
											? i18n.jamSession.storageFullTooltip
											: isRecordingIconButtonDisabled() ? i18n.jamSession.recordingNotAllowed : recordingDetails?.recordingStarted
												? i18n.jamSession.stopRecordingTooltip
												: i18n.jamSession.startRecordingTooltip
									}
									disableInteractive
								>
									<div style={{
										opacity: isRecordingIconButtonDisabled() ? 0.5 : 1,
										cursor:
											isRecordingIconButtonDisabled()
												? "not-allowed"
												: "pointer"
									}}>
										<IconButton
											sx={{ p: 0 }}
											className="sessionIconBtn"
											onClick={handleRecordingIconClick}
											disabled={isRecordingIconButtonDisabled()}
										>
											{recordingDetails?.recordingStarted ? (
												<img src={ActiveRecording} alt="Record" />
											) : (
												<img src={Recording} alt="Record" />
											)}
										</IconButton>
									</div>
								</Tooltip>
							)}
							<Tooltip
								title={
									isMixerOpen
										? i18n.jamSession.closeMixerTooltip
										: i18n.jamSession.mixerTooltip
								}
							>
								<IconButton
									sx={{
										p: 0,
										backgroundColor: isMixerOpen ? "#FFF069 !important" : "",
										borderRadius: "6px",
									}}
									className="sessionIconBtn"
									onClick={toggleMixer}
								>
									{isMixerOpen ? (
										<img src={ActiveMixer} alt="active=mixer" />
									) : (
										<img src={MixerIcon} alt="mixer" />
									)}
								</IconButton>
							</Tooltip>
						</DailyActionsWrapper>
						<Box sx={{ justifyContent: "flex-end", ml: "10px" }}>
							<EndCallButton
								broadcastResponse={null}
								streamStartedBy={streamStartedBy || liveStreamingStartedBy}
								onLeaveCall={leaveSession}
								isRecordingStarted={recordingDetails?.recordingStarted}
								isLiveStreaming={isLiveStreaming}
							/>
						</Box>
					</div>

					<Popper
						id="simple-popper"
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
						placement="bottom"
						sx={{ zIndex: 10 }}
					>
						<Box
							sx={{
								p: 1,
								bgcolor: "#000000",
								mt: "15px",
								border: "1px solid #5C5C5C",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "6px",
							}}
						>
							<div>
								{Constants.emojis.map((emojiObj: any) => (
									<IconButton
										key={emojiObj.key}
										sx={{
											color: "grey",
										}}
										onClick={() => handleEmoji(emojiObj)}
									>
										{emojiObj.emoji}
									</IconButton>
								))}
							</div>
						</Box>
					</Popper>
					<Menu
						anchorEl={anchorElRecording}
						open={Boolean(anchorElRecording)}
						onClose={handleCloseRecording}
						sx={{
							'& .MuiPaper-root': {
								backgroundColor: '#454545',
								color: 'white',
							},
						}}
					>
						{RecordingMenu.map((menuItem) => (
							<MenuItem key={menuItem.id} onClick={() => handleRecordingMenuItemClick(menuItem.id)}>
								<ListItemIcon>
									<img src={menuItem.icon} alt={`${menuItem.label} icon`} style={{ width: 24, height: 24 }} />
								</ListItemIcon>
								<ListItemText
									primary={
										<Typography variant="body1" sx={{ color: '#FFF' }}>
											{menuItem.label}
										</Typography>
									}
								/>
							</MenuItem>
						))}
					</Menu>
					{(recordingDetails?.recordingStarted || isLiveStreaming) &&
						renderSnackbar({
							isOpen: open,
							handleClose: () => setOpen(false),
							snackbarMessage,
							iconOnClick: () => setOpen(false),
						})}
					{(!recordingDetails?.recordingStarted || isLiveStreamingEnded) &&
						renderSnackbar({
							isOpen: endSnackBarOpen,
							handleClose: handdleSnackbarClose,
							snackbarMessage,
							iconOnClick: () => setEndSnackBarOpen(false),
						})}

					<ActionDialog
						open={openRecordingEndDialog}
						handleClose={handleCloseEndRecordingDialog}
						dialogWidth="600px"
						buttonsAlignment="right"
						showCloseIcon={false}
						title=""
						titleStyle={{
							color: "#5B5B65",
							fontSize: "1.5rem",
							fontWeight: "500",
						}}
						titleAlignment="center"
						actionButtons={
							<>
								<Button
									sx={{
										width: "7.8125rem",
										height: "2.5rem",
										borderRadius: "2.5rem",
									}}
									className="cancel-button"
									size="large"
									variant="outlined"
									onClick={handleCloseEndRecordingDialog}
								>
									Cancel
								</Button>
								<Button
									sx={{
										width: "12.5625rem",
										height: "2.5rem",
										borderRadius: "1.25rem",
										backgroundColor: "rgba(235, 42, 0, 1)"
									}}
									className="submit-button"
									size="large"
									variant="contained"
									onClick={handleEndRecording}
								>
									Stop Recording
								</Button>
							</>
						}
					>
						<Typography
							variant="body1"
							component="p"
							sx={{ mb: 3, fontWeight: 500, fontSize: "1.375rem" }}
						>
							Do you want to Stop Recording?
						</Typography>
						<Typography
							variant="body1"
							component="p"
							sx={{ mb: 3, fontWeight: 400, fontSize: "1rem", color: "#4D4C4F" }}
						>
							Recorded Duration {" "} <span style={{ fontSize: "18px", fontWeight: 400, color: "#000" }}>{formatRecordingTimer(recordingTimer)}</span>
						</Typography>
					</ActionDialog>


					<StartLiveStream
						twilioSessionId={twilioSessionId}
						openStartLiveStreamDialog={openStartLiveStreamDialog}
						handleCloseStartLiveStreamDialog={handleCloseStartLiveStreamDialog}
						startLiveStream={startLiveStream}
					/>
					<EndLiveStream
						twilioSessionId={twilioSessionId}
						openEndLiveStreamDialog={openEndLiveStreamDialog}
						handleCloseEndLiveStreamDialog={handleCloseEndLiveStreamDialog}
						endLiveStream={endLiveStream}
					/>

					<SessionInformation
						twilioSessionId={twilioSessionId}
						sessionInformationDialogBoxOpen={sessionInformationDialogBoxOpen}
						closeSessionInformationDialogBox={closeSessionInformationDialogBox}
						isBroadcastingStarted={isLiveStreaming}
					/>
					{/*  </AppBar> */}
					{/* <Mixer mixer={isMixerOpen} />
			*/}
					{/* <SnackbarAlert /> */}
					{/* </div> */}
				</TrayContainer>
			</TrayWrapper>
		);
	};

	return <>{JamSessionMenubar()}</>;
}

export default memo(DailyMenuBar);
