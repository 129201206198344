import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
export const normalizeAudioControls = (payloadData: any) => {
 let audioControls = payloadData?.allUsersAudioControls;
 const defaultAudio = {
  master: { audio_status: "unmute", audio_level: 50 },
  channel_1: { audio_status: "unmute", audio_level: 50 },
  channel_2: { audio_status: "unmute", audio_level: 50 },
  mic: { audio_status: "mute", audio_level: 0 },
 };

 try {
  const parsedControls = JSON.parse(audioControls);

  return parsedControls.map((userAudioControl: any) => {
   let audioData;

   // Check if audio exists and parse it if it's a JSON string
   try {
    audioData = userAudioControl.audio
     ? JSON.parse(userAudioControl.audio)
     : {};
   } catch (err) {
    audioData = {};
   }

   // Merge audio data with default audio structure
   const normalizedAudio = { ...defaultAudio, ...audioData };
   return {
    email: userAudioControl.email,
    audio: normalizedAudio,
   };
  });
 } catch (error) {
  console.error("Error normalizing audio controls:", error);
  return [];
 }
};


export const syncAudioControls = (twilioSessionId: any) => {
  const requestMessage = {
    action: "SYNC_AUDIO_CONTROLS",
    timestamp: new Date().toISOString(),
    session_id: twilioSessionId,
    // created_by: created_by
  };
  WebsocketClientUSerStatus.handleMixerAudioControl(requestMessage);
};